import React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { initializeApp } from "firebase/app";
import firebaseConfig from './firebaseConfig';
import Main from './main/Main';
import * as serviceWorker from './serviceWorker';

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import cyan from "@material-ui/core/colors/cyan";
import "typeface-roboto";

const theme = createTheme({
  palette: {
    type: "dark",
    primary: cyan,
    secondary: {
      main: "#000000",
    },
  },
});

initializeApp(firebaseConfig);

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Main />
      </CssBaseline>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

