import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import useZoom from "../../hooks/useZoom";
import CustomTooltip from "../../components/Chart/CustomTooltip";
import Card from "../../components/Card";

const useStyles = makeStyles({
  chart: {
    fontFamily: "Roboto",
    userSelect: "none",
    overflow: "visible",
    marginTop: "1%",
    "& .recharts-wrapper": {
      margin: "auto",
    },
    "& .recharts-default-tooltip": {
      textAlign: "center",
      "& p": {
        fontWeight: 700,
        textDecoration: "underline",
      },
    },
  },
});

function CurrentLineChart({
  XDomain,
  setXDomain,
  entries,
  currentIndex,
  setCurrentIndex
}) {
  const classes = useStyles();
  const {
    zoomStart,
    tempZoomEnd,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove
  } = useZoom(setXDomain);
  const currentTimestamp = new Date(
    entries[currentIndex]?.timestamp
  ).valueOf();
  const data = entries.map((entry) => {
    if (!entry?.energy?.current && !entry?.energy?.currentStm) {
      return {
        timestamp: new Date(entry.timestamp).valueOf(),
      };
    }

    if (entry.energy?.current?.stm || entry.energy?.current?.ltc) {
      return {
        current: entry.energy.current.ltc,
        currentStm: entry.energy.current.stm,
        timestamp: new Date(entry.timestamp).valueOf(),
      };
    }

    return {
      current: entry.energy.current,
      currentStm: entry.energy.currentStm,
      timestamp: new Date(entry.timestamp).valueOf(),
    };
  });

  const yAxis = [0, "dataMax + 10"];

  const maxAmplitude = Math.max(
    ...data.map(e => {
      return (
        Math.max(e.current ?? 0, e.currentStm ?? 0)
      );
    })
  );

  if (maxAmplitude <= 10) {
    yAxis[1] = 10;
  }

  return (
    <Card title="Current">
      <ResponsiveContainer className={classes.chart} width="100%" height={200}>
        <LineChart
          data={data}
          syncId="session"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onClick={(e) => {
            const index = entries.findIndex(
              (item) => new Date(item.timestamp).valueOf() === e?.activeLabel
            );

            setCurrentIndex(index);
          }}
        >
          <Line
            dataKey="current"
            name="Current"
            dot={false}
            stroke="#0eb6bd"
            connectNulls={true}
          />
          <Line
            dataKey="currentStm"
            name="Current STM"
            dot={false}
            stroke="#0eb6bd"
            connectNulls={true}
          />
          <ReferenceLine
            x={currentTimestamp}
            stroke="white"
          />
          {
            zoomStart && tempZoomEnd && (
              <ReferenceArea
                x1={zoomStart}
                x2={tempZoomEnd}
                strokeOpacity={0}
                fillOpacity={0.1}
              />
            )
          }
          <Tooltip
            content={
              <CustomTooltip
                unit="A"
                labelFormatter={(label) => new Date(label).toLocaleString()}
              />
            }
          />
          <XAxis
            type="number"
            domain={XDomain}
            dataKey="timestamp"
            textAnchor="end"
            allowDataOverflow={true}
            tick={true}
            stroke="#FFFFFF"
            tickFormatter={(ts) => new Date(ts).toLocaleTimeString('sv-SE')}
          />
          <YAxis
            stroke="#FFFFFF"
            type="number"
            domain={yAxis}
            unit="A"
            tick
            tickFormatter={(value) => value.toFixed(0)}
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default CurrentLineChart;
