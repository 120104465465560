import React, { useState } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  ReferenceArea,
} from 'recharts';
import { Paper, Typography, Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ExportToCSV from '../../../../../components/ExportToCSV';
import { capitalize } from '../../../../../utils';

const toTimestamp = (startTime) => (msPassed) => {
  return new Date(new Date(startTime).valueOf() + msPassed).getTime();
};

const toTimeString = startTime => msPassed => {
  return new Date(new Date(startTime).valueOf() + msPassed).toLocaleTimeString();
}

const toHex = number => {
  return `0x${number.toString(16).padStart(2, '0').toUpperCase()}`;
}

const toBool = number => {
  if (typeof number === 'boolean') {
    number = Number(number);
  }

  if (number > 0 && number < 1) {
    return '';
  }

  return capitalize(Boolean(number).toString());
}

const formatValue = (value, name) => {
  switch (name) {
    case 'vescBootStatus': {
      switch (value) {
        case 0:
          return 'BOOT_PIN_OFF';
        case 1:
          return 'BOOT_PIN_ON';
        case 2:
          return 'BOOT_PIN_TMO';
        case 3:
          return 'BOOT_PIN_MAX_RETRIES';
        case 4:
          return 'VERIFIED';

        default:
          return value;
      }
    }

    default:
      return value;
  }
}

function CustomTooltip({ startTime, label, payload, type }) {
  if (!label) {
    return null;
  }

  return (
    <Paper style={{ padding: 5 }}>
      <Typography variant="subtitle1">{new Date(toTimestamp(startTime)(label)).toLocaleTimeString()}</Typography>
      {
        (payload || []).map((entry) => {
          let value = entry.value;

          if (type === 'hex') {
            value = toHex(entry.value);
          } else if (type === 'bool') {
            value = toBool(entry.value);
          } else {
            value = formatValue(entry.value, entry.name);
          }

          return (
            <Typography variant="body1" key={`${label}_${entry.value}`}>
              {entry.dataKey}: {value}
            </Typography>
          );
        })
      }
    </Paper>
  );
}

const useStyles = makeStyles({
  chart: {
    fontFamily: 'Roboto',
    userSelect: 'none',
    overflow: 'visible',
    marginTop: '1%',
    '& .recharts-wrapper': {
      margin: 'auto',
    },
    '& .recharts-default-tooltip': {
      textAlign: 'center',
      '& p': {
        fontWeight: 700,
        textDecoration: 'underline',
      },
    },
  },
});

function SessionGraph(props) {
  const {
    sessionId,
    eventId,
    graphId,
    type,
    data,
    startTime,
    endTime,
    index,
    setIndex,
    timestamps,
  } = props;

  let initialXDomain = [timestamps?.first ?? 0, timestamps?.last ?? 'auto'];

  if ((timestamps?.first ?? 0) <= 20000) {
    if (startTime && endTime) {
      initialXDomain = [new Date(startTime), new Date(endTime) - new Date(startTime)];
    }
  }

  const [ zoomStart, setZoomStart ] = useState(null);
  const [ tempZoomEnd, setTempZoomEnd ] = useState(null);
  const [ XDomain, setXDomain ] = useState(initialXDomain);

  const classes = useStyles();
  const theme = useTheme();
  const stroke = theme.palette.text.primary;

  const handleMouseDown = (e) => {
    if (e && e.activeLabel) {
      setTempZoomEnd(null);
      setZoomStart(e.activeLabel);
    }
  };

  const handleMouseUp = (e) => {
    if (e && e.activeLabel) {
      if (zoomStart && tempZoomEnd) {
        setTempZoomEnd(null);
        setXDomain([
          Math.min(zoomStart, e.activeLabel),
          Math.max(zoomStart, e.activeLabel)
        ]);
      }

      setZoomStart(null);
    }
  };

  const handleMouseMove = (e) => {
    if (e && e.activeLabel && zoomStart) {
      setTempZoomEnd(e.activeLabel);
    }
  }

  const handleClick = (e) => {
    if (!zoomStart && !tempZoomEnd) {
      if (e && e.activeLabel) {
        setIndex(e.activeLabel);
      }
    }
  };

  const getTickFormatter = () => {
    if (eventId === 'errCode' || graphId === 'errCode') {
      return toHex;
    }

    if (type === 'boolean') {
      return toBool;
    }

    return formatValue;
  };

  return (
    <>
      {
        XDomain[0] !== initialXDomain[0] && XDomain[1] !== initialXDomain[1] && (
          <Button color="primary" onClick={() => setXDomain(initialXDomain)}>
            Reset zoom
          </Button>
        )
      }
      <ResponsiveContainer
        className={classes.chart}
        width="100%"
        height={200}
      >
        <LineChart
          data={data}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onClick={handleClick}
        >
          {
            (['errCode', 'vescBootStatus'].includes(eventId) || ['errCode', 'hc', 'host'].includes(graphId))
              ? (
                <Line
                  type="stepAfter"
                  dataKey={graphId}
                  name={graphId}
                  connectNulls={false}
                  dot={{ r: 4 }}
                  activeDot={{ r: 2 }}
                  stroke="none"
                  fill="#0eb6bd"
                />
              )
              : (
                <Line
                  type="stepAfter"
                  dataKey={graphId}
                  name={graphId}
                  connectNulls={true}
                  strokeWidth={2}
                  dot={false}
                  stroke="#0eb6bd"
                />
              )
          }
          <Tooltip
            content={
              <CustomTooltip
                startTime={startTime}
                type={
                  eventId === 'errCode' || graphId === 'errCode'
                    ? 'hex'
                    : (
                      type === 'boolean'
                        ? 'bool'
                        : null
                    )}
              />
            }
          />
          <XAxis
            type="number"
            domain={XDomain}
            dataKey="timestamp"
            textAnchor="end"
            allowDataOverflow={true}
            tick={true}
            stroke="#ffffff"
            tickFormatter={toTimeString(startTime)}
          />
          <YAxis
            stroke={stroke}
            type={(['errCode', 'vescBootStatus'].includes(eventId) || ['errCode'].includes(graphId)) ? 'category' : 'number'}
            allowDuplicatedCategory={false}
            tickFormatter={getTickFormatter()}
            tick={!(['errCode', 'vescBootStatus'].includes(eventId) || ['errCode'].includes(graphId))}
          />
          <ReferenceLine x={index} />
          {
            zoomStart && tempZoomEnd && (
              <ReferenceArea x1={zoomStart} x2={tempZoomEnd} />
            )
          }
        </LineChart>
      </ResponsiveContainer>
      <ExportToCSV
        data={data.map(e => ({ timestamp: e.timestamp, [e.id]: e[e.id] }))}
        fileName={`${eventId}_${sessionId}`}
      />
    </>
  );
}

export default React.memo(SessionGraph);
