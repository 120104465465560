function styles(theme) {
  return {
    button: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      container: {
        maxHeight: '100%',
        overflowY: 'scroll',
      },
      searchForm: {
        overflowY: 'scroll',
      },
      searchCard: {
        position: 'relative',
      },
      searchCardActions: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
      },
      sessionsCard: {
        height: '100%',
      },
      sessionsCardContent: {
        maxHeight: '100%',
        overflowY: 'scroll',
      },
      errorText: {
        textAlign: 'center',
        display: 'block',
      },
    }
  }
}

export default styles;
