import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { LinearProgress, Button } from "@material-ui/core";
import {
  DataGrid,
  GridColumns,
  GridFilterModel,
  GridInputSelectionModel,
  GridOverlay,
  GridRowId,
  getGridStringOperators,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/styles";
import qs from "query-string";
import prettyMilliseconds from "pretty-ms";
import httpStatusCodes from "http-status-codes";

import { useSessions } from "../hooks/useSessions";

const useStyles = makeStyles(() => ({
  dataGrid: {
    "& .MuiDataGrid-row": {
      cursor: "pointer",
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  },
}));

function LoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

function ErrorOverlay(props: {
  name: string;
  retries: number;
  setRetries: (retries: number) => void;
}) {
  const { name, retries, setRetries } = props;

  let httpError = "";

  try {
    httpError = httpStatusCodes.getStatusText(name);
  } catch (e) {
    httpError = "Unknown error";
  }

  return (
    <GridOverlay>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <code>{httpError}</code>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setRetries(retries + 1)}
        >
          Retry
        </Button>
      </div>
    </GridOverlay>
  );
}

function NoRowsOverlay() {
  return (
    <GridOverlay style={{ zIndex: 5 }}>
      <div
        style={{
          display: "flex",
          flexFlow: "column wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <code style={{ maxWidth: "75%" }}>No data</code>
      </div>
    </GridOverlay>
  );
}

function Previews() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [limit, setLimit] = useState(25);
  const activeSessionId = location.pathname
    .split("/")
    .filter((p) => p && p !== "sessions")
    .at(-1) as GridRowId;
  const [filter, setFilter]: [
    GridFilterModel,
    (model: GridFilterModel) => void
  ] = useState([] as unknown as GridFilterModel);
  const params = qs.parse(location.search);

  const [retries, setRetries] = useState(0);
  const [page, setPage] = useState(0);
  const [data, loading, error] = useSessions(
    { ...params, limit, page },
    filter,
    retries
  );

  const [selectedRows, setSelectedRows]: [
    GridInputSelectionModel,
    (model: GridInputSelectionModel) => void
  ] = useState([activeSessionId] as GridInputSelectionModel);

  useEffect(() => {
    if (!loading) {
      setSelectedRows([activeSessionId]);
    }
  }, [loading, limit, activeSessionId, setSelectedRows]);

  const formattedData =
    data?.sessions?.map((s) => ({
      id: s.id,
      startDate: new Date(s.startDate ?? Date.now()).toLocaleString("sv-SE"),
      batteryId: s.batteryId,
      hardwareId: s.hardwareId,
      sessionType: s.sessionType,
      duration: prettyMilliseconds(
        (s?.timestamps?.stop ?? 0) - (s?.timestamps?.start ?? 0),
        {
          compact: true,
        }
      ),
      nrfFwVersion: s.classes?.generic?.firmware_version,
      vbmsFwVersion: s.classes?.vbms?.firmware_version,
      escFwVersion: s.classes?.esc?.firmware_version,
    })) ?? [];

  const defaultOperators = getGridStringOperators();
  const equalOperator = defaultOperators.filter(
    ({ value }) => value === "equals"
  );
  const isNotEmptyOperator = defaultOperators.filter(
    ({ value }) => value === "isNotEmpty"
  );
  const columns: GridColumns = [
    {
      field: "startDate",
      headerName: "Timestamp",
      width: 155,
      filterOperators: isNotEmptyOperator,
      renderCell: (content) => (
        <Link
          style={{ textDecoration: "none", color: "white" }}
          to={`/sessions/${content.row.id}${location.search}`}
        >
          {content?.value}
        </Link>
      ),
    },
    {
      field: "batteryId",
      headerName: "Battery",
      width: 145,
      filterOperators: equalOperator,
    },
    {
      field: "sessionType",
      headerName: "Session type",
      width: 160,
      filterOperators: equalOperator,
    },
    {
      field: "hardwareId",
      headerName: "Hardware ID",
      width: 185,
      filterOperators: equalOperator,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 145,
      filterOperators: equalOperator,
    },
    {
      field: "nrfFwVersion",
      headerName: "nRF",
      width: 145,
      filterOperators: equalOperator,
      hide: true,
    },
    {
      field: "vbmsFwVersion",
      headerName: "VBMS",
      width: 145,
      filterOperators: equalOperator,
      hide: true,
    },
    {
      field: "escFwVersion",
      headerName: "ESC",
      width: 145,
      filterOperators: equalOperator,
      hide: true,
    },
  ];

  return (
    <DataGrid
      style={{ height: "100%" }}
      className={classes.dataGrid}
      density="compact"
      hideFooterSelectedRowCount
      loading={loading}
      components={{
        LoadingOverlay: LoadingOverlay,
        ErrorOverlay: ErrorOverlay,
        NoRowsOverlay: NoRowsOverlay,
      }}
      componentsProps={{ errorOverlay: { retries, setRetries } }}
      error={error}
      columns={columns}
      rows={formattedData ?? []}
      rowCount={data?.total ?? 0}
      rowsPerPageOptions={[10, 25, 50]}
      paginationMode="server"
      filterMode="server"
      pagination
      pageSize={limit}
      page={page}
      onRowClick={(e) => {
        navigate(`/sessions/${e.row.id}${location.search}`);
      }}
      onPageSizeChange={(newPageSize) => {
        setLimit(newPageSize);
        setPage(0);
      }}
      onPageChange={(newPage) => {
        setPage(newPage);
      }}
      selectionModel={selectedRows}
      onFilterModelChange={(model) => setFilter(model)}
    />
  );
}

export default Previews;
