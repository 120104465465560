import React, { useState } from 'react';
import { Parser } from 'json2csv';
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import Download from '@material-ui/icons/CloudDownload';
import Check from '@material-ui/icons/Check';
import Loop from '@material-ui/icons/Loop';

// Use these when upgraded mui to v5
//import { Downloading } from '@material-ui/icons';
//import { Download } from '@material-ui/icons';
//import { DownloadDone } from '@material-ui/icons';

function Icon({ loading, success }) {
  if (loading) {
    return <Loop />;
  }

  if (success) {
    return <Check />;
  }

  return <Download />;
}

/**
 * Flatten a multidimensional object
 *
 * For example:
 *   flattenObject{ a: 1, b: { c: 2 } }
 * Returns:
 *   { a: 1, c: 2}
 */
function flattenObject(obj) {
  let flattened = {}

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      flattened = {
        ...flattened,
        ...flattenObject(value),
      };
    } else if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        const entry = value[i];
        flattened[`${camelToSnakeCase(key)}_${i + 1}`] = entry;
      }
    } else {
      flattened[camelToSnakeCase(key)] = value;
    }
  })

  return flattened
}

function camelToSnakeCase(str) {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
};

function ExportToCSV({ fileName, data, headers }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const parser = new Parser(headers && { headers });
  const flattenedData = data.map(entry => flattenObject(entry));

  const handleClick = async () => {
    setLoading(true);
    setSuccess(false);
    const csv = 'data:text/csv;charset=utf-8,' + parser.parse(flattenedData);
    const downloadUrl = encodeURI(csv);
    const link = document.createElement('a');

    link.setAttribute('href', downloadUrl);
    link.setAttribute('download', `${fileName}.csv`);
    link.click();
    link.remove();

    setLoading(false);
    setSuccess(true);
  };

  return (
    <Tooltip title="Export to CSV">
      <IconButton
        color="primary"
        disabled={loading}
        onClick={handleClick}
      >
        <Icon loading={loading} success={success} />
      </IconButton>
    </Tooltip>
  );
}

export default ExportToCSV;
