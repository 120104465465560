import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import Preview from "./Preview";
import FirmwareDropdown from "./FirmwareDropdown";
import HwRevisionDropdown from "./HwRevisionDropdown";
import TypeDropdown from "./TypeDropdown";
import Error from "../../../components/Error";

const initialSearch = {
  type: "",
  firmware: "",
  hwRevision: "",
  jetpack: "",
};

function Previews({ loading, rides, selected, onClick }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState(initialSearch);

  useEffect(() => {
    setSearch(initialSearch);
    setPage(0);
  }, [rides]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <LinearProgress variant="indeterminate" />;
  }

  if (
    !loading &&
    (!rides || (rides.headers?.length === 0 && rides.sessions?.length === 0))
  ) {
    return <Error message="No results" />;
  }

  let { sessions = [], headers = [] } = rides;
  let mergedSessions = [...sessions, ...headers];

  mergedSessions = mergedSessions
    .map((session) => {
      const dateTime = moment(session?.startDateTime ?? session?.dateTime);
      const duration = session.minutes ?? Math.round(session.seconds / 60);
      const jetpackType = session?.deviceType ?? session?.jetpackType ?? "-";

      return {
        ...session,
        dateTime,
        duration,
        jetpackType,
      };
    })
    .filter((session) => {
      const year = new Date(
        session.startDateTime || session.dateTime
      ).getFullYear();

      if (2019 > year || year > new Date().getFullYear()) {
        return false;
      }

      if (search.type) {
        if (session?.jetpackType !== search.type) {
          return false;
        }
      }

      if (search.firmware) {
        const fw =
          session?.jetpackType === "G3"
            ? session?.versions?.firmware?.[0] ?? session?.versions?.fw ?? "-"
            : `${session?.firmwareVersion}.${session?.firmwareVersionThalamus}.${session?.firmwareVersionFlexor}`;

        if (fw !== search.firmware) {
          return false;
        }
      }

      if (search.hwRevision) {
        const hw = session?.hardwareRevision;

        if (!hw) {
          return false;
        }

        if (!hw.includes(search.hwRevision)) {
          return false;
        }
      }

      if (search.jetpack) {
        const jp = session?.jetpackId ?? session?.jetpackID;

        if (!jp?.includes(search.jetpack)) {
          return false;
        }
      }

      return true;
    })
    .sort((a, b) => {
      return (
        new Date(b.startDateTime || b.dateTime) -
        new Date(a.startDateTime || a.dateTime)
      );
    });

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, mergedSessions.length - page * rowsPerPage);

  const sessionsToList = mergedSessions.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <TableContainer component={Paper} style={{ width: "100%" }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell size="small">
                <TextField label="Date" disabled />
              </TableCell>
              <TableCell size="small">
                <TextField label="Place" disabled />
              </TableCell>
              <TableCell size="small">
                <TextField label="Duration" disabled />
              </TableCell>
              <TableCell size="small">
                <TextField
                  label="Jetpack"
                  onChange={(e) => {
                    setSearch({ ...search, jetpack: e.target.value });
                    setPage(0);
                  }}
                />
              </TableCell>
              <TableCell size="small">
                <FirmwareDropdown
                  rides={rides}
                  firmware={search.firmware}
                  hwRevision={search.hwRevision}
                  jetpack={search.jetpack}
                  type={search.type}
                  onChange={(fw) => {
                    setSearch({ ...search, firmware: fw });
                    setPage(0);
                  }}
                />
              </TableCell>
              <TableCell size="small">
                <HwRevisionDropdown
                  rides={rides}
                  firmware={search.firmware}
                  hwRevision={search.hwRevision}
                  jetpack={search.jetpack}
                  type={search.type}
                  onChange={(hwRevision) => {
                    setSearch({ ...search, hwRevision });
                    setPage(0);
                  }}
                />
              </TableCell>
              <TableCell size="small">
                <TypeDropdown
                  rides={rides}
                  firmware={search.firmware}
                  hwRevision={search.hwRevision}
                  jetpack={search.jetpack}
                  type={search.type}
                  onChange={(type) => {
                    setSearch({
                      ...search,
                      type,
                      firmware: "",
                      hwRevision: "",
                    });
                    setPage(0);
                  }}
                />
              </TableCell>
              <TableCell size="small" />
            </TableRow>
          </TableHead>
          <TableBody>
            {sessionsToList.map((session) => (
              <Preview
                session={session}
                selected={selected}
                onClick={onClick}
                key={session?.uniqueId}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 45 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          count={mergedSessions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}

export default Previews;
