import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "@material-ui/core";
import queryString from "query-string";

import SearchField from "../components/Search/SearchField";
import DatePickers from "../components/Search/DatePickers";

function validateMAC(mac) {
  const re =
    /^([0-9A-Fa-fXx]{2}[:]){5}([0-9A-Fa-fXx]{2})$|^(0x[0-9A-FXx]{12})$|^([0-9A-FXx]{12})$/;

  return re.test(mac);
}

function formatMAC(mac) {
  const reWithColons = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/;
  const reWithout0x = /^([0-9A-F]{12})$/;

  if (reWithColons.test(mac)) {
    return mac.split(":").join("");
  }

  if (reWithout0x.test(mac)) {
    return `0x${mac}`;
  }

  return mac;
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function SearchPanel({ query, fromDate, toDate, loading }) {
  const [enableDateRange, setEnableDateRange] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const initialState = {
    query: query?.trim() ?? "",
    fromDate,
    toDate,
  };

  const [searchState, setSearchState] = useState(initialState);

  const handleSubmit = (e) => {
    e.preventDefault();

    setSearchState({
      ...searchState,
    });

    let query = searchState?.query?.trim() ?? "";
    let search = {
      ...searchState,
    };

    if (validateMAC(query)) {
      search = {
        ...search,
        batteryId: formatMAC(query),
      };
    } else if (validateEmail(query)) {
      search = {
        ...search,
        email: query,
      };
    } else {
      search = {
        ...search,
        uid: query,
      };
    }

    navigate(`${location.pathname}?${queryString.stringify(search)}`);
  };

  return (
    <form onSubmit={handleSubmit} style={{ marginBottom: 15 }}>
      <Card>
        <CardHeader title="Search Options" />
        <CardContent>
          <SearchField
            helperText="Email/UID/MAC"
            value={searchState?.query}
            onChange={(value) => {
              setSearchState({
                ...searchState,
                query: value,
              });
            }}
          />
          <DatePickers
            enableNoDateRangeButton
            enableDateRange={enableDateRange}
            fromDate={searchState?.fromDate}
            toDate={searchState?.toDate}
            onChange={(newFromDate, newToDate) => {
              setSearchState({
                ...searchState,
                fromDate: new Date(newFromDate).toISOString(),
                toDate: new Date(newToDate).toISOString(),
              });
            }}
            onChangeEnableDateRange={(value) => {
              setEnableDateRange(value);

              if (value === false) {
                setSearchState({
                  ...searchState,
                  fromDate: null,
                  toDate: null,
                });
              }
            }}
            controls
            time={false}
          />
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => {
              setSearchState({
                ...initialState,
                query: "",
              });
              setEnableDateRange(false);
              navigate(location.pathname);
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Search
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

export default SearchPanel;
