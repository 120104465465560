import React from 'react';
import {
  Typography,
  Slider,
} from '@material-ui/core';

function GpsFilterSliders(props) {
  const {
    disabled,
    distance,
    onDistanceThresholdChange,
    hdop,
    onHDOPThresholdChange,
    speed,
    onSpeedThresholdChange,
  } = props;

  function formatDistance(value) {
    return `${value}m`;
  }

  return (
    <>
      <Typography id="distance-slider" gutterBottom>
        Max distance between two points (m)
      </Typography>
      <Slider
        disabled={disabled}
        defaultValue={distance}
        getAriaValueText={formatDistance}
        onChange={(e, value) => {
          onDistanceThresholdChange(value);
        }}
        aria-labelledby="distance-slider"
        valueLabelDisplay="auto"
        step={1}
        min={0}
        max={100}
        marks={[
          {
            value: 0,
            label: '0',
          },
          {
            value: 100,
            label: '100',
          }
        ]}
      />
      <Typography id="hdop-slider" gutterBottom gutterTop>
        Max HDOP
      </Typography>
      <Slider
        disabled={disabled}
        defaultValue={hdop}
        onChange={(e, value) => {
          onHDOPThresholdChange(value);
        }}
        aria-labelledby="hdop-slider"
        valueLabelDisplay="auto"
        step={1}
        min={0}
        max={20}
        marks={[
          {
            value: 0,
            label: '0',
          },
          {
            value: 20,
            label: '20',
          }
        ]}
      />
      <Typography id="speed-slider" gutterBottom gutterTop>
        Max Speed
      </Typography>
      <Slider
        disabled={disabled}
        defaultValue={speed}
        onChange={(e, value) => {
          onSpeedThresholdChange(value);
        }}
        aria-labelledby="speed-slider"
        valueLabelDisplay="auto"
        step={5}
        min={5}
        max={100}
        marks={[
          {
            value: 5,
            label: '5',
          },
          {
            value: 100,
            label: '100',
          }
        ]}
      />
    </>
  );
}

export default GpsFilterSliders;