import React from "react";
import { useParams } from "react-router";
import LinearProgress from "@material-ui/core/LinearProgress";
import G3ContentContainer from "./G3/ContentContainer";
import { useSessionHeader } from "../../hooks";

function ContentContainer() {
  const { sessionId } = useParams();
  const [sessionHeader, sessionHeaderLoading] = useSessionHeader(sessionId);

  if (!sessionId) {
    return null;
  }

  if (sessionHeaderLoading) {
    return <LinearProgress variant="indeterminate" />;
  }

  return <G3ContentContainer session={sessionHeader} />;
}

export default ContentContainer;
