import React from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import Card from "../../components/Card";
import CopyToClipboard from "../../components/CopyToClipboardButton";
import { decamelize } from "../../utils";

function SessionSummaryCard({ data }) {
  const knownKeys = {
    lowestStateOfCharge: "Lowest state of charge",
    highestStateOfCharge: "Highest state of charge",
    stateOfHealth: "State of health",
    lowestDelta: "Lowest voltage delta",
    highestDelta: "Highest voltage delta",
    latestDelta: "Latest voltage delta",
    lowestCellTemperature: "Lowest cell temperature",
    highestCellTemperature: "Highest cell temperature",
    highestHumidity: "Highest humidity",
    lowestHumidity: "Lowest humidity",
    highestMoistureSensorVoltage: "Highest moisture sensor voltage",
    cycleCount: "Cycle count",
  };

  // Initialize summary with null values in order to keep the order
  const summary = Object.keys(knownKeys).reduce((acc, key) => {
    acc[knownKeys[key]] = null;
    return acc;
  }, {});

  for (const [key, value] of Object.entries(data?.summary ?? {})) {
    if (value === null) {
      const knownKey = knownKeys[key];
      summary[knownKey ?? decamelize(key)] = "-";
      continue;
    }

    switch (key) {
      case "lowestStateOfCharge":
        summary[knownKeys[key]] = `${value}%`;
        break;
      case "highestStateOfCharge":
        summary[knownKeys[key]] = `${value}%`;
        break;
      case "stateOfHealth":
        summary[knownKeys[key]] = `${value}%`;
        break;
      case "lowestDelta":
        summary[knownKeys[key]] = `${value}V`;
        break;
      case "highestDelta":
        summary[knownKeys[key]] = `${value}V`;
        break;
      case "latestDelta":
        summary[knownKeys[key]] = `${value}V`;
        break;
      case "lowestCellTemperature":
        summary[knownKeys[key]] = `${value}°C`;
        break;
      case "highestCellTemperature":
        summary[knownKeys[key]] = `${value}°C`;
        break;
      case "highestHumidity":
        summary[knownKeys[key]] = `${value}%`;
        break;
      case "lowestHumidity":
        summary[knownKeys[key]] = `${value}%`;
        break;
      case "highestMoistureSensorVoltage":
        summary[knownKeys[key]] = `${value}V`;
        break;
      case "cycleCount":
        summary[knownKeys[key]] = value;
        break;
      default:
        summary[decamelize(key)] = value;
        break;
    }
  }

  return (
    <Card
      title="Session summary"
      actions={
        <CopyToClipboard
          header={`Session summary - ${data.id}`}
          content={summary}
        />
      }
    >
      <Table size="small">
        <TableBody>
          {Object.entries(summary)
            .filter(([, value]) => value !== null)
            .map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>{value}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Card>
  );
}

export default SessionSummaryCard;
