import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from '@material-ui/core';
import McConf from './McConf';
import { decamelize } from '../../../../utils';

function TemporaryMode({ data, loading }) {
  const mcConf = Object.entries(data?.[0]?.data ?? {})
    .filter(([key, value]) => Object.keys(value).includes('min') && Object.keys(value).includes('max'))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

  const rest = Object.entries(data?.[0]?.data ?? {})
    .filter(([key, value]) => !Object.keys(value).includes('min') && !Object.keys(value).includes('max'))
    .map(([key, value]) => typeof value === 'boolean' ? [key, value.toString()] : [key, value]);

  return (
    <McConf title="Temporary Mode" data={Object.keys(mcConf).length > 0 ? [{ data: { ...mcConf } }] : []} loading={loading} size={10}>
      {
        data.length > 0 && (
          <>
            <TableContainer style={{ marginBottom: 15 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Parameter</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    rest.map(([key, value]) => (
                      <TableRow key={`${key}_${value.timestamp}`}>
                        <TableCell>{decamelize(key)}</TableCell>
                        <TableCell>{value}</TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <Divider style={{ marginBottom: 15 }} />
          </>
        )
      }
    </McConf>
  );
}

export default TemporaryMode;