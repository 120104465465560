import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import useZoom from "../../hooks/useZoom";
import CustomTooltip from "../../components/Chart/CustomTooltip";
import Card from "../../components/Card";

const useStyles = makeStyles({
  chart: {
    fontFamily: "Roboto",
    userSelect: "none",
    overflow: "visible",
    marginTop: "1%",
    "& .recharts-wrapper": {
      margin: "auto",
    },
    "& .recharts-default-tooltip": {
      textAlign: "center",
      "& p": {
        fontWeight: 700,
        textDecoration: "underline",
      },
    },
  },
});

function SystemConnectorVoltageLineChart({
  XDomain,
  setXDomain,
  entries,
  currentIndex,
  setCurrentIndex,
}) {
  const classes = useStyles();
  const {
    zoomStart,
    tempZoomEnd,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
  } = useZoom(setXDomain);

  const currentTimestamp = new Date(
    entries[currentIndex]?.timestamp
  ).valueOf();
  const data = entries.map((entry) => {
    let result = {
      timestamp: new Date(entry.timestamp).valueOf(),
      voltage: entry.environment?.moist,
    };

    if (!entry.environment?.moist) {
      return {
        timestamp: new Date(entry.timestamp).valueOf(),
      };
    }

    return result;
  });

  return (
    <Card title="System connector moisture sensor">
      <ResponsiveContainer className={classes.chart} width="100%" height={200}>
        <LineChart
          data={data}
          syncId="session"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onClick={(e) => {
            const index = entries.findIndex(
              (item) => new Date(item.timestamp).valueOf() === e?.activeLabel
            );

            setCurrentIndex(index);
          }}
        >
          <Line
            dataKey="voltage"
            name="Voltage"
            dot={false}
            stroke="#0eb6bd"
            connectNulls={true}
          />
          <ReferenceLine
            x={currentTimestamp}
            stroke="white"
          />
          {
            zoomStart && tempZoomEnd && (
              <ReferenceArea
                x1={zoomStart}
                x2={tempZoomEnd}
                strokeOpacity={0}
                fillOpacity={0.1}
              />
            )
          }
          <Tooltip
            content={
              <CustomTooltip
                unit="V"
                labelFormatter={(label) => new Date(label).toLocaleString()}
              />
            }
          />
          <XAxis
            type="number"
            domain={XDomain}
            dataKey="timestamp"
            textAnchor="end"
            allowDataOverflow={true}
            tick={true}
            stroke="#FFFFFF"
            tickFormatter={(ts) => {
              return new Date(ts).toLocaleTimeString();
            }}
          />
          <YAxis
            stroke="#FFFFFF"
            type="number"
            domain={[0, "dataMax + 10"]}
            unit="V"
            tick
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default SystemConnectorVoltageLineChart;
