import React from 'react';
import { makeStyles } from "@material-ui/styles";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import useZoom from "../../hooks/useZoom";
import CustomTooltip from "../../components/Chart/CustomTooltip";
import Card from "../../components/Card";
import { round } from "../../utils";

const useStyles = makeStyles({
  chart: {
    fontFamily: "Roboto",
    userSelect: "none",
    overflow: "visible",
    marginTop: "1%",
    "& .recharts-wrapper": {
      margin: "auto",
    },
    "& .recharts-default-tooltip": {
      textAlign: "center",
      "& p": {
        fontWeight: 700,
        textDecoration: "underline",
      },
    },
  },
});

function SOHLineChart({
  XDomain,
  setXDomain,
  entries,
  currentIndex,
  setCurrentIndex,
}) {
  const classes = useStyles();
  const {
    zoomStart,
    tempZoomEnd,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
  } = useZoom(setXDomain);

  const currentTimestamp = new Date(
    entries[currentIndex]?.timestamp
  ).valueOf();
  const data = entries.map((entry) => {
    if (!entry?.energy?.soh) {
      return {
        timestamp: new Date(entry.timestamp).valueOf(),
      };
    }

    return {
      soh: round(1)(entry.energy.soh),
      timestamp: new Date(entry.timestamp).valueOf(),
    };
  });

  return (
    <Card title="State of health">
      <ResponsiveContainer className={classes.chart} width="100%" height={200}>
        <LineChart
          data={data}
          syncId="session"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onClick={(e) => {
            const index = entries.findIndex(
              (item) => new Date(item.timestamp).valueOf() === e?.activeLabel
            );

            setCurrentIndex(index);
          }}
        >
          <Line
            dataKey="soh"
            name="State of health"
            dot={false}
            stroke="#0eb6bd"
            connectNulls={true}
          />
          <ReferenceLine
            x={currentTimestamp}
            stroke="white"
          />
          {
            zoomStart && tempZoomEnd && (
              <ReferenceArea
                x1={zoomStart}
                x2={tempZoomEnd}
                strokeOPacity={0}
                fillOpacity={0.3}
              />
            )
          }
          <Tooltip
            content={
              <CustomTooltip
                unit="%"
                labelFormatter={(label) => new Date(label).toLocaleString()}
              />
            }
          />
          <XAxis
            type="number"
            domain={XDomain}
            dataKey="timestamp"
            textAnchor="end"
            allowDataOverflow={true}
            tick={true}
            stroke="#FFFFFF"
            tickFormatter={(ts) => {
              return new Date(ts).toLocaleTimeString();
            }}
          />
          <YAxis
            stroke="#FFFFFF"
            type="number"
            domain={[0, 100]}
            unit="%"
            tick
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default SOHLineChart;
