import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import titleBarStyle from '../assets/jss/components/titleBarStyle';

const useStyles = makeStyles(titleBarStyle);

function TitleBar({ children, onMenuToggle }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            onClick={onMenuToggle}
            color="inherit"
            aria-label="Menu"
           >
            <MenuIcon />
          </IconButton>
          {children}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default TitleBar;
