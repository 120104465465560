import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
  Chip,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFetch } from 'use-http';
import DownloadButton from '../../../../components/DownloadButton';
import { decamelize, capitalize } from '../../../../utils/';
import styles from '../../../styles/dataCardStyle';
import constants from '../../../constants';

const { ENDPOINT_RUNS } = constants;

const useStyles = makeStyles(styles);

function Metadata({ session }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    loading: downloadUrlsLoading,
    data: downloadUrls = null,
  } = useFetch(`${ENDPOINT_RUNS}/downloads/${session.id}`, {}, []);

  function formatValues(entries) {
    let formattedEntries = [];

    for (const [key, value] of entries) {
      switch (key) {
        case 'versions': {
          for (const [target, version] of Object.entries(value)) {
            if (target === 'firmware' && Array.isArray(version)) {
              const versionParts = [
                'FW Version',
                'FW Type',
                'FW Build Date',
                'FW Builder',
              ];

              for (let i = 0; i < version.length; ++i) {
                formattedEntries.push([versionParts[i] || '-', version[i]]);
              }
            } else {
              formattedEntries.push([`${capitalize(target)} Version`, version]);
            }
          }
          break;
        }

        case 'batteryDegradation': {
          formattedEntries.push([decamelize(key), `${value}%`]);
          break;
        }

        case 'batteryType': {
          formattedEntries.push(['Battery type (soft)', value]);
          break;
        }

        case 'batteryTypeBMS': {
          formattedEntries.push(['Battery type (BMS)', value]);
          break;
        }

        case 'summary': {
          for (const [summaryKey, summaryValue] of Object.entries(value)) {
            if (summaryKey === 'rideTime') {
              const seconds = summaryValue % 60;
              const minutes = (summaryValue - seconds) / 60

              formattedEntries.push([decamelize(summaryKey), `${minutes}m ${seconds}s`]);
            } else {
              formattedEntries.push([decamelize(summaryKey), summaryValue]);
            }
          }

          break;
        }

        default: {
          if (key.toLowerCase().includes('date')) {
            formattedEntries.push([decamelize(key), new Date(value).toLocaleString()]);
          } else if (value && typeof value === 'object' && !Array.isArray(value)) {
            formattedEntries.push([decamelize(key), JSON.stringify(value)]);
          } else {
            formattedEntries.push([decamelize(key), value || '-']);
          }
        }
      }
    }

    return formattedEntries;
  }

  const bannedKeys = [
    'data',
    'uniqueId',
    'filePath',
    'subCollections',
    'weather',
  ];

  const data = formatValues(
    Object.entries(session)
      .filter(([key]) => !bannedKeys.includes(key))
      .sort((a, b) => a[0].localeCompare(b[0]))
  );

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data?.length ?? 0 - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const start = page * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = data.slice(start, end);

  return (
    <Card className={classes.card}>
      <CardHeader
        size="small"
        title="Metadata"
        action={
          <Grid container spacing={1}>
            {
              !downloadUrlsLoading && (
                <>
                  {
                    downloadUrls?.data?.downloadUrl && (
                      <Grid item>
                        <DownloadButton
                          url={downloadUrls?.data?.downloadUrl}
                          fileName={session.id}
                          title="Download parsed log"
                        />
                      </Grid>
                    )
                  }
                  {
                    downloadUrls?.data?.rawSessionDownloadUrl && (
                      <Grid item>
                        <DownloadButton
                          url={downloadUrls?.data?.rawSessionDownloadUrl}
                          fileName={session.id}
                          title="Download raw log"
                        />
                      </Grid>
                    )
                  }
                </>
              )
            }
            <Grid item>
              <Chip variant="outlined" color="primary" label="G3" style={{ marginLeft: 5, marginRight: 5 }} />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                Key
              </TableCell>
              <TableCell>
                Value
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              currentData.map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              ))
            }
            {
              emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={2} />
                </TableRow>
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={2}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </CardContent>
    </Card>
  );
}

export default Metadata;
