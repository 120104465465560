import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  Bar,
  LabelList,
} from "recharts";
import Card from "../../components/Card";
import CustomTooltip from "../../components/Chart/CustomTooltip";

function TemperatureChart({ temps }) {
  const cellTemps = temps?.cells?.map((temp, index) => ({
    location: `Cell ${index + 1}`,
    temp,
  })) ?? [];

  if (cellTemps.length === 0) {
    return null;
  }

  const otherTemps = Object.entries(temps)
    .filter(
      ([key, value]) =>
        // Filter out null and undefined values and cells array + legacy keys from old API
        !["cells", "humidity", "moist"].includes(key) &&
        value !== null &&
        value !== undefined
    )
    .map(([key, value]) => ({ location: key.toUpperCase(), temp: value }));

  const allTemps = [...cellTemps, ...otherTemps];

  if (allTemps.length === 0) {
    return null;
  }

  return (
    <Card
      title="Temperature"
      subheader={`Max cell temp: ${Math.max(...temps.cells)}°C`}
    >
      <ResponsiveContainer width="100%" height={200}>
        <BarChart unit="°C" data={allTemps}>
          <XAxis dataKey="location" stroke="#ffffff" />
          <YAxis unit="°C" domain={[0, "dataMax + 10"]} stroke="#ffffff" />
          <ChartTooltip content={<CustomTooltip />} />
          <Bar dataKey="temp" fill="#0eb6bd">
            <LabelList
              style={{ fontWeight: 100 }}
              dataKey="temp"
              position="top"
              stroke="#ffffff"
              formatter={(value) => `${value}°C`}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default TemperatureChart;
