import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Event from './Event';

function Events({
  session,
  events,
  startTime,
  endTime,
  index,
  setIndex,
  timestamps,
}) {
  const blacklist = [
    'walltime',
    'vescMcconf',
    'tmpMode',
    'constants',
  ];

  events = events?.filter((event) => !blacklist.includes(event));

  const [items, setItems] = useState(events);

  if (!events) {
    return null;
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container style={{ overflowY: 'scroll', width: '100%' }}>
        <Droppable droppableId="droppable" style={{ width: '100%' }}>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ width: '100%' }}
            >
              {
                items.map((event, i) => (
                  <Grid item xs={12} key={event}>
                    <Event
                      session={session}
                      event={event}
                      startTime={startTime}
                      endTime={endTime}
                      draggableIndex={i}
                      index={index}
                      setIndex={setIndex}
                      style={{width: '100%'}}
                      timestamps={timestamps}
                    />
                  </Grid>
                ))
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Grid>
    </DragDropContext>
  );
}

export default React.memo(Events);
