import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

function LoadingGps() {
  return (
    <Skeleton
      animation="wave"
      variant="rect"
      width="100%"
      height={393.3}
    />
  );
}

export default LoadingGps;
