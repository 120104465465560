import { useFetch, IncomingOptions, CachePolicies } from "use-http";
import qs, { StringifiableRecord } from "query-string";
import constants from "../rides/constants";
import { ISessionMetadata, HttpResponse, Session } from "@radinnab/core-types";
import { GridFilterModel } from "@material-ui/data-grid";

type SessionsResponse = {
  page: number;
  total: number;
  totalPages: number;
  sessions: ISessionMetadata[];
};

const { ENDPOINT_SESSIONS } = constants;

const options: IncomingOptions = {
  retries: 2,
  retryOn: async ({ response }) => {
    return Boolean(response && response.status >= 300);
  },
  retryDelay: () => {
    return 1000;
  },
  cachePolicy: CachePolicies.NO_CACHE,
  persist: false,
};

function useSessions(
  params: StringifiableRecord,
  filter: GridFilterModel,
  retries: number
): [SessionsResponse, boolean, Error] {
  if (filter) {
    params = {
      ...params,
      ...filter?.items?.reduce((acc, item) => {
        if (!item.columnField) return acc;

        return {
          ...acc,
          [item.columnField]: item.value,
        };
      }, {}),
    };
  }
  const query = qs.stringify(params);

  const url = `${ENDPOINT_SESSIONS}${query && `?${query}`}`;

  const {
    loading,
    error,
    data = {},
  } = useFetch(url, options, [query, retries]);

  return [data?.data ?? {}, loading, error];
}

function useSession(
  id: string | undefined
): [Session | undefined, boolean, Error] {
  let url = `${ENDPOINT_SESSIONS}/${id}`;

  const { loading, error, data } = useFetch<HttpResponse<Session>>(
    url,
    options,
    [id]
  );

  return [data?.data, loading, error];
}

export { useSessions, useSession };
