function styles(theme){
  const drawerWidth = '30%';

  return {
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
      flexShrink: 0,
      zIndex: theme.zIndex.appBar - 1,
      position: 'relative',
    },
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      [theme.breakpoints.up("md")]: {
        ...theme.mixins.toolbar,
      },
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      padding: 3,
      marginBottom: 2,
    },
  };
};

export default styles;
