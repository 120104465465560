import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { Drawer, SwipeableDrawer, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router-dom";
import clsx from "clsx";

import ContentContainer from "./content/ContentContainer";
import Menu from "./menu/Menu";

import "../assets/styles/rides.css";
import styles from "./styles/appStyles";

const useStyles = makeStyles(styles);

function App({ drawerOpen, setDrawerOpen }) {
  const classes = useStyles();
  const { sessionId } = useParams();

  useEffect(() => {
    if (setDrawerOpen) {
      setDrawerOpen(false);
    }
  }, [sessionId, setDrawerOpen]);

  return (
    <>
      <Hidden mdUp implementation="css">
        <SwipeableDrawer
          variant="temporary"
          open={drawerOpen}
          anchor="left"
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          onOpen={(e) => {
            if (
              e?.type === "keydown" &&
              (e?.key === "Tab" || e?.key === "Shift")
            ) {
              return;
            }

            setDrawerOpen(true);
          }}
          onClose={(e) => {
            if (
              e?.type === "keydown" &&
              (e?.key === "Tab" || e?.key === "Shift")
            ) {
              return;
            }

            setDrawerOpen(false);
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.drawerHeader} />
          <Menu session={sessionId} />
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open
          anchor="left"
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
        >
          <div className={classes.drawerHeader} />
          <Menu session={sessionId} />
        </Drawer>
      </Hidden>

      <Routes>
        <Route
          path="/*"
          element={
            <main className={clsx(classes.content, classes.contentShift)}>
              <ContentContainer session={sessionId} />
            </main>
          }
        />
      </Routes>
    </>
  );
}

export default App;
