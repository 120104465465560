import React, { useState } from 'react';
import { Routes, Route, NavLink } from "react-router-dom";
import { Button, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { Provider } from "use-http";
import { useAuth } from "../firebase";
import TitleBar from "../components/TitleBar";
import LoginMenu from "./LoginMenu";
import routes from "../routes";
import mainStyle from "../assets/jss/components/mainStyle";
import Rides from "../rides/App";
import Batteries from "../batteries/App";
import Sessions from "../sessions/App";
import FrontPage from "../frontPage/FrontPage";
import Error from "../components/Error";

const useStyles = makeStyles(mainStyle);

const LinkButton = ({ path, className, children }) => (
  <Button color="secondary" component={NavLink} to={path} className={className}>
    {children}
  </Button>
);

function Main() {
  const { user, loading, error } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const classes = useStyles();

  const isDev = JSON.parse(process.env.REACT_APP_IS_DEV);

  if (loading) {
    return <LinearProgress variant="indeterminate" />;
  }

  if (error) {
    return <Error message={`Failed to login: ${error}`} />;
  }

  if (!user && !isDev) {
    return (
      <>
        <TitleBar
          title="Radinn"
          onMenuToggle={() => setDrawerOpen(!drawerOpen)}
          className={classes.titleBar}
        >
          <div className={classes.linkContainer}>
            <LinkButton className={classes.logo} path="/">
              <img
                alt="Radinn logo"
                src="https://cdn.radinn.com/-/media/Project/Radinn/Radinn/Logo-Images/Radinn_H_BB_RGB_black.svg"
              />
            </LinkButton>
          </div>
          <LoginMenu />
        </TitleBar>
        <Error message="Unauthorized - please sign in" />
      </>
    );
  }

  const globalOptions = {
    interceptors: {
      request: async ({ options }) => {
        const app = getApp();
        const auth = getAuth(app);
        const token = await auth?.currentUser?.getIdToken();

        if (token) {
          options.headers = {
            Authorization: `Bearer ${token}`,
          };
        }

        return options;
      },
    },
    cachePolicy: "no-cache",
    persist: false,
  };

  return (
    <Provider options={globalOptions}>
      <TitleBar
        title="Radinn"
        onMenuToggle={() => setDrawerOpen(!drawerOpen)}
        className={classes.titleBar}
      >
        <div className={classes.linkContainer}>
          <LinkButton className={classes.logo} path="/">
            <img
              alt="Radinn logo"
              src="https://cdn.radinn.com/-/media/Project/Radinn/Radinn/Logo-Images/Radinn_H_BB_RGB_black.svg"
            />
          </LinkButton>
          {routes
            .filter((route) => route.text)
            .map((route) => (
              <LinkButton key={route.path} path={route.path}>
                {route.text}
              </LinkButton>
            ))}
        </div>
        <LoginMenu />
      </TitleBar>
      {isDev || user ? (
        <Routes>
          <Route
            path="/rides/:sessionId/*"
            element={
              <Rides drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            }
          />

          <Route
            path="/rides/*"
            element={
              <Rides drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            }
          />

          <Route
            path="/batteries/:sessionId"
            element={
              <Batteries
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
              />
            }
          />

          <Route
            path="/batteries/*"
            element={
              <Batteries
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
              />
            }
          />

          <Route
            path="/sessions/*"
            element={
              <Sessions drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            }
          />

          <Route path="/" exact element={<FrontPage />} />
        </Routes>
      ) : (
        <Error message="Unauthorized - please sign in." />
      )}
    </Provider>
  );
}

export default Main;
