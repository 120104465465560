import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
  Chip,
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ErrorIcon from "@material-ui/icons/Error";
import SettingsIcon from "@material-ui/icons/Settings";
import { BatteryFaultCode } from "@radinnab/core-types";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import ExportToCSV from "../../components/ExportToCSV";
import DownloadJson from "../../components/DownloadJson";
import CopyToClipboard from "../../components/CopyToClipboardButton";
import OpenSettingsInNewTab from "../../components/OpenSettingsInNewTab";

function MetadataCard({ data }) {
  const metadata = {
    "Battery ID": data?.batteryId ?? "-",
    "Product type": data?.type ?? "-",
    "Hardware revision": data.hardwareRevision
      ? `${data.modelNumber} ${data.hardwareRevision}`
      : "-",
    "Battery type": data?.batteryType || "-",
    "User ID": data?.uid ?? "-",
    "Rider ID": data?.riderId ?? "-",
    "Firmware version BMS": data?.versions?.vbms ?? data?.versions?.bms ?? "-",
  };

  if (data?.versions?.["nrf5-sdk"]) {
    metadata["Firmware version nRF"] = data.versions["nrf5-sdk"];
  }

  if (data?.versions?.["esp-wifi"]) {
    metadata["Firmware version ESP"] = data.versions["esp-wifi"];
  }

  const currentUrlSearchParams = new URLSearchParams(window.location.search);
  const previousChunkUrl = `/batteries/sessions/${data?.previousChunk}${
    currentUrlSearchParams.toString()
      ? `?${currentUrlSearchParams.toString()}`
      : ""
  }`;
  const nextChunkUrl = `/batteries/sessions/${data?.nextChunk}${
    currentUrlSearchParams.toString()
      ? `?${currentUrlSearchParams.toString()}`
      : ""
  }`;

  const isDecom = data?.latestStatus?.states?.decommissioned;
  const faultCode = data?.latestStatus?.states?.fault?.code;
  const faultMessage = BatteryFaultCode[faultCode];

  return (
    <Card
      title={`Session ${new Date(data?.sessionStartDate).toLocaleString(
        "sv-SE"
      )}`}
      headerAction={
        data?.previousChunk || data?.nextChunk ? (
          <>
            <Tooltip title="Previous session chunk">
              <IconButton
                disabled={!data?.previousChunk}
                component={Link}
                to={previousChunkUrl}
              >
                <ChevronLeft />
              </IconButton>
            </Tooltip>
            <Tooltip title="Next session chunk">
              <IconButton
                disabled={!data?.nextChunk}
                component={Link}
                to={nextChunkUrl}
              >
                <ChevronRight />
              </IconButton>
            </Tooltip>
          </>
        ) : null
      }
      actions={
        <>
          <ExportToCSV data={data.entries} fileName={data.id} />
          <DownloadJson data={data.entries} fileName={data.id} />
          <OpenSettingsInNewTab
            title="View settings"
            data={{
              settings: {
                ...data.settings,
                ...data.settingsBms, // legacy
              },
            }}
            icon={<SettingsIcon />}
          />
          {isDecom && faultCode && faultCode !== 0 && (
            <OpenSettingsInNewTab
              title="View fault"
              data={{ ...data.latestStatus.states.fault }}
              icon={<ErrorIcon />}
            />
          )}
          <CopyToClipboard
            header={`Session metadata - ${data.id}`}
            content={metadata}
          />
        </>
      }
    >
      <Table size="small">
        <TableBody>
          {Object.entries(metadata).map(([key, value]) => {
            return (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>{value}</TableCell>
              </TableRow>
            );
          })}
          {isDecom && (
            <TableRow>
              <TableCell>Decommissioned</TableCell>
              <TableCell>
                <Chip
                  label={
                    faultMessage
                      ? `Decommissioned due to ${faultMessage} (code ${faultCode})`
                      : "Decommissioned"
                  }
                  color="default"
                  icon={<ErrorIcon />}
                />
              </TableCell>
            </TableRow>
          )}
          {data?.latestRide && (
            <TableRow>
              <TableCell>Latest ride</TableCell>
              <TableCell>
                <Link
                  style={{ color: "white", textDecoration: "none" }}
                  to={`/rides/${data.latestRide}`}
                >
                  {data.latestRide}
                </Link>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Card>
  );
}

export default MetadataCard;
