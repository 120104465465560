import React from "react";
import {
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
} from "recharts";
import Card from "../components/Card";
import { ChartProps } from "./types";

function LineChart({
  title,
  unit,
  XDomain,
  YDomain,
  YAxisType,
  startTimestamp,
  entries,
  attributes,
  highlightedIndex,
  setHighlightedIndex,
}: ChartProps) {
  const shouldRender: boolean = Boolean(
    entries.find((entry) => {
      return attributes.some(
        (attribute) =>
          entry[attribute.key] !== undefined && entry[attribute.key] !== null
      );
    })
  );

  if (!shouldRender) {
    return null;
  }

  startTimestamp = new Date(startTimestamp).valueOf() ?? 0;

  for (const attribute of attributes) {
    if (attribute.formatter) {
      for (const entry of entries) {
        entry[attribute.key] = attribute.formatter(entry[attribute.key]);
      }
    }
  }

  return (
    <Card title={title}>
      <ResponsiveContainer width="100%" height={150}>
        <RechartsLineChart
          data={entries}
          syncId="session"
          onClick={(next) => {
            if (next.activeTooltipIndex) {
              setHighlightedIndex(next.activeTooltipIndex);
            }
          }}
        >
          {attributes.map((attribute) => (
            <Line
              key={attribute.key}
              dataKey={attribute.key}
              name={attribute.label}
              dot={entries.length < 10}
              stroke="#0eb6bd"
              connectNulls={false}
            />
          ))}
          <Tooltip
            active={true}
            formatter={(l: string) => `${l}${unit}`}
            labelFormatter={(l: number) =>
              `${new Date(startTimestamp + l).toLocaleTimeString("sv-SE")}`
            }
            contentStyle={{ backgroundColor: "#424242" }}
          />
          <XAxis
            type="number"
            domain={XDomain}
            dataKey="timestamp"
            textAnchor="end"
            allowDataOverflow={true}
            tick={true}
            stroke="#FFFFFF"
            tickFormatter={(ts) => {
              return new Date(startTimestamp + ts).toLocaleTimeString("sv-SE");
            }}
          />
          <YAxis
            stroke="#FFFFFF"
            type={YAxisType || "number"}
            domain={YDomain || ["auto", "auto"]}
            unit={unit}
            tick
          />
          {entries[highlightedIndex]?.timestamp && (
            <ReferenceLine
              x={entries[highlightedIndex].timestamp as number}
              stroke="#FFFFFF"
              strokeDasharray="3 3"
            />
          )}
        </RechartsLineChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default LineChart;
