import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import useZoom from "../../hooks/useZoom";
import CustomTooltip from "../../components/Chart/CustomTooltip";
import Card from "../../components/Card";

const useStyles = makeStyles({
  chart: {
    fontFamily: "Roboto",
    userSelect: "none",
    overflow: "visible",
    marginTop: "1%",
    "& .recharts-wrapper": {
      margin: "auto",
    },
    "& .recharts-default-tooltip": {
      textAlign: "center",
      "& p": {
        fontWeight: 700,
        textDecoration: "underline",
      },
    },
  },
});

function HumidityLineChart({
  XDomain,
  setXDomain,
  entries,
  currentIndex,
  setCurrentIndex,
}) {
  const classes = useStyles();
  const {
    zoomStart,
    tempZoomEnd,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
  } = useZoom(setXDomain);

  const currentTimestamp = new Date(entries[currentIndex]?.timestamp).valueOf();
  const data = entries
    .map((entry) => {
      if (!entry?.environment?.humidity) {
        return {
          timestamp: new Date(entry.timestamp).valueOf(),
        };
      }

      if (typeof entry.environment.humidity === "number") {
        return {
          timestamp: new Date(entry.timestamp).valueOf(),
          humidity: entry.environment.humidity,
        };
      }

      const { vsense, ...rest } = entry.environment.humidity;

      let result = {
        timestamp: new Date(entry.timestamp).valueOf(),
        ...rest,
      };

      return result;
    })
    .filter((item) => Object.keys(item).length > 1); // more than just timestamp

  return (
    <Card title="Relative humidity">
      <ResponsiveContainer className={classes.chart} width="100%" height={200}>
        <LineChart
          data={data}
          syncId="session"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onClick={(e) => {
            const index = entries.findIndex(
              (item) => new Date(item.timestamp).valueOf() === e?.activeLabel
            );

            setCurrentIndex(index);
          }}
        >
          {Object.keys(data[0] || {}).map((key) => {
            if (key === "timestamp") {
              return null;
            }

            return (
              <Line
                key={key}
                dataKey={key}
                name={key}
                dot={false}
                stroke="#0eb6bd"
                connectNulls={true}
              />
            );
          })}
          <ReferenceLine x={currentTimestamp} stroke="white" />
          {zoomStart && tempZoomEnd && (
            <ReferenceArea
              x1={zoomStart}
              x2={tempZoomEnd}
              strokeOpacity={0}
              fillOpacity={0.1}
            />
          )}
          <Tooltip
            content={
              <CustomTooltip
                unit="%"
                labelFormatter={(label) => new Date(label).toLocaleString()}
              />
            }
          />
          <XAxis
            type="number"
            domain={XDomain}
            dataKey="timestamp"
            textAnchor="end"
            allowDataOverflow={true}
            tick={true}
            stroke="#FFFFFF"
            tickFormatter={(ts) => {
              return new Date(ts).toLocaleTimeString();
            }}
          />
          <YAxis
            stroke="#FFFFFF"
            type="number"
            domain={[0, "dataMax + 10"]}
            unit="%"
            tick
            tickFormatter={(tick) => tick.toFixed(0)}
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default HumidityLineChart;
