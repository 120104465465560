import React from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell, Chip, IconButton } from "@material-ui/core";
import OpenRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import GpsStatus from "./GpsStatus";

const hwRevisionRegex = /v(\d+\.)?(\d+\.)?(\*|\d+)/;

function Preview({ session, selected }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <TableRow
      key={session.uniqueId}
      hover
      selected={selected === session.uniqueId}
      onClick={() => {
        /*
          Can't wrap table row in a Link or use component={Link}
          since that is invalid HTML (which results in errors)
        */
        navigate(`/rides/${session.uniqueId}${location.search}`);
      }}
      style={{
        cursor: "pointer",
        textDecoration: "none",
      }}
    >
      <TableCell size="small">
        {moment(session?.startDateTime ?? session?.dateTime).format(
          "YYYY-MM-DD HH:mm"
        )}
      </TableCell>
      <TableCell size="small">
        <GpsStatus session={session} />
      </TableCell>
      <TableCell size="small">{`~${session?.duration}m`}</TableCell>
      <TableCell size="small">
        {session?.jetpackId ?? session?.jetpackID ?? "-"}
      </TableCell>
      <TableCell size="small">
        {session?.versions?.firmware?.[0] ?? session?.versions?.fw ?? "-"}
      </TableCell>
      <TableCell size="small">
        {session?.hardwareRevision?.match(hwRevisionRegex)?.[0] ?? "-"}
      </TableCell>
      <TableCell size="small">
        <Chip variant="outlined" color="primary" label={session?.jetpackType} />
      </TableCell>
      <TableCell size="small">
        <IconButton
          component={Link}
          to={`/rides/${session.uniqueId}${location.search}`}
        >
          <OpenRightIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

Preview.propTypes = {
  session: PropTypes.shape({
    uniqueId: PropTypes.string.isRequired,
    dateTime: PropTypes.oneOf([PropTypes.string, PropTypes.Date]).isRequired,
    duration: PropTypes.number.isRequired,
    jetpackId: PropTypes.string,
    jetpackID: PropTypes.string,
    jetpackType: PropTypes.string,
    versions: PropTypes.shape({
      firmware: PropTypes.arrayOf(PropTypes.string),
    }),
    firmwareVersion: PropTypes.string,
    firmwareVersionThalamus: PropTypes.string,
    firmwareVersionFlexor: PropTypes.string,
  }).isRequired,
  selected: PropTypes.string,
  onClick: PropTypes.func,
};

export default Preview;
