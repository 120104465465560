import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Drawer, SwipeableDrawer, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import qs from "query-string";
import SessionContainer from "./SessionContainer";
import Menu from "./Menu";

import "../assets/styles/batteries.css";
import styles from "./styles/appStyles";

type Props = {
  drawerOpen?: boolean;
  setDrawerOpen?: (open: boolean) => void;
};

const useStyles = makeStyles(styles as any);

function App({ drawerOpen, setDrawerOpen }: Props) {
  const classes = useStyles();
  const location = useLocation();

  const { query, fromDate, toDate } = qs.parse(location.search);

  useEffect(() => {
    if (setDrawerOpen) {
      setDrawerOpen(false);
    }
  }, [setDrawerOpen]);

  return (
    <>
      <Hidden mdUp implementation="css">
        <SwipeableDrawer
          variant="temporary"
          open={drawerOpen ?? false}
          anchor="left"
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          onOpen={(e) => {
            const kbEvent = e as unknown as KeyboardEvent;
            if (
              kbEvent?.type === "keydown" &&
              (kbEvent?.key === "Tab" || kbEvent?.key === "Shift")
            ) {
              return;
            }

            if (setDrawerOpen) {
              setDrawerOpen(true);
            }
          }}
          onClose={(e) => {
            const kbEvent = e as unknown as KeyboardEvent;
            if (
              kbEvent?.type === "keydown" &&
              (kbEvent?.key === "Tab" || kbEvent?.key === "Shift")
            ) {
              return;
            }

            if (setDrawerOpen) {
              setDrawerOpen(false);
            }
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.drawerHeader} />
          <Menu />
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open
          anchor="left"
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
        >
          <div className={classes.drawerHeader} />
          <Menu
            query={query?.toString()}
            fromDate={fromDate?.toString()}
            toDate={toDate?.toString()}
          />
        </Drawer>
      </Hidden>

      <Routes>
        <Route
          path="/:sessionId"
          element={
            <main className={clsx(classes.content, classes.contentShift)}>
              <SessionContainer />
            </main>
          }
        />
      </Routes>
    </>
  );
}

export default App;
