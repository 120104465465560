const firebaseConfig = {
  apiKey: "AIzaSyDKlTaSM6qmjzrLXGsUJ8BzT8oIJwLaCrk",
  authDomain: "radinn-analytics.firebaseapp.com",
  databaseURL: "https://radinn-analytics.firebaseio.com",
  projectId: "radinn-analytics",
  storageBucket: "radinn-analytics.appspot.com",
  messagingSenderId: "543003884275",
  appId: "1:543003884275:web:2f901391b15c264a"
};

export default firebaseConfig;

