import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { v4 as uuidv4 } from 'uuid';

function Errors({ errors, loading, startTime }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, errors?.length ?? 0 - page * rowsPerPage);

  if (loading) {
    return (
      <Card>
        <CardHeader title="Errors" />
        <CardContent>
          {
            Array(emptyRows).fill(0).map(() => (
              <Skeleton key={`empty_row_${uuidv4()}`} style={{ height: 37, marginBottom: 5 }} />
            ))
          }
        </CardContent>
      </Card>
    );
  }

  if (!errors || errors?.length === 0) {
    return (
      <Card>
        <CardHeader title="Errors" />
        <CardContent>
          <Typography variant="h6">
            No errors found
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const start = page * rowsPerPage;
  const end = start + rowsPerPage;
  const currentErrors = errors.slice(start, end);

  return (
    <Card>
      <CardHeader title="Errors" />
      <CardContent>
        <TableContainer>
          <Table style={{ minWidth: 500 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Error</TableCell>
                <TableCell>Timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                currentErrors
                  .map(error => (
                    <TableRow key={uuidv4()}>
                      <TableCell>
                        { errors.findIndex(e => e.timestamp === error.timestamp) + 1 }
                      </TableCell>
                      <TableCell>{error.data}</TableCell>
                      <TableCell>
                        {
                          new Date(
                            new Date(startTime).valueOf() + error.timestamp
                          ).toLocaleTimeString()
                        }
                      </TableCell>
                    </TableRow>
                  ))
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={3} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={3}
                  count={errors.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}

export default Errors;

