import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import Card from "../../components/Card";
import { ISessionMetadata } from "@radinnab/core-types";
import prettyMilliseconds from "pretty-ms";

type Props = {
  summary: ISessionMetadata["summary"];
};

function SummaryCard({ summary }: Props) {
  if (!summary) {
    return (
      <Card title="Summary">
        <Typography variant="body1">No summary available</Typography>
      </Card>
    );
  }

  return (
    <Card title="Summary">
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>Max Speed</TableCell>
            <TableCell>
              {summary.speed.max} km/h (avg. {summary.speed.avg} km/h)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Max Current</TableCell>
            <TableCell>
              {summary.current.max}A (avg. {summary.current.avg}A)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Distance</TableCell>
            <TableCell>{summary.distance}m</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Ride Time</TableCell>
            <TableCell>
              {prettyMilliseconds(summary.rideTimeSeconds * 1000)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Ampere hours used</TableCell>
            <TableCell>{summary.usage.ampHours}Ah</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
}

export default SummaryCard;
