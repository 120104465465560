import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Map, Marker, Polyline, GoogleApiWrapper } from 'google-maps-react';

const style = {
  map: {
    position: 'relative',
    width: '100%',
    height: '100%',
  }
};

function rad(x) {
  return x * Math.PI / 180;
}

function findClosestIndex(coords, lat, lng) {
  const R = 6371; // radius of earth in km
  const distances = [];
  let closest = -1;

  for(let i = 0; i < coords.length; ++i) {
    const coord = coords[i];
    const dLat  = rad(coord.lat - lat);
    const dLong = rad(coord.lng - lng);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    distances[i] = d;

    if (closest === -1 || d < distances[closest]) {
      closest = i;
    }
  }

  return closest;
}

function GoogleMap(props) {
  const {
    classes,
    data,
    currentPos,
    google,
    centerLat,
    centerLng,
    currentInterval,
    children,
    onClick,
  } = props;
  const [ bounds, setBounds ] = useState(null);
  const [ current, setCurrent ] = useState(currentPos);

  useEffect(() => {
    const newBounds = new google.maps.LatLngBounds();

    for (let item of data) {
      newBounds.extend(item);
    }

    setBounds(newBounds);
  }, [google.maps.LatLngBounds, data]);

  useEffect(() => {
    setCurrent(currentPos);
  }, [currentPos]);

  const currentData = currentInterval ? data.slice(currentInterval[0], currentInterval[1]) : data;

  function handleClick(props, polyline, e) {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    const index = findClosestIndex(currentData, lat, lng);

    setCurrent(currentData[index]);

    if (onClick) {
      onClick(index);
    }
  }

  return (
    <div className="map">
      <Map
        google={google}
        mapType="SATELLITE"
        mapTypeControl={false}
        classNames={classes.map}
        streetViewControl={false}
        initialCenter={{
          lat: centerLat,
          lng: centerLng,
        }}
        bounds={bounds}
      >
        <Polyline
          path={currentData}
          strokeColor="#0eb6bd"
          strokeWeight={4}
          onClick={handleClick}
        />
        <Polyline
          path={currentData}
          strokeColor="#fff"
          strokeWeight={2}
          onClick={handleClick}
        />
        <Marker position={current} icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: 3,
          fillColor: '#0eb6bd',
          strokeColor: '#0eb6bd',
          fillOpacity: 1,
        }} />
      </Map>
      {children}
    </div>
  );
};

GoogleMap.propTypes = {
  apiKey: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  currentPos: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  data: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  })).isRequired,
  centerLat: PropTypes.number.isRequired,
  centerLng: PropTypes.number.isRequired,
};

GoogleMap.defaultProps = {
  currentPos: null,
};

export default withStyles(style)(GoogleApiWrapper(
  (props) => ({
    apiKey: props.apiKey,
  }
))(GoogleMap));
