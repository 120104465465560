import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import copy from "copy-to-clipboard";

function CopyToClipboardButton({ header, content }) {
  return (
    <Tooltip title="Copy to clipboard">
      <IconButton onClick={() => copy(format(header, content))}>
        <CopyIcon color="primary" />
      </IconButton>
    </Tooltip>
  );
}

function format(header, content) {
  let str = header + "\n";

  for (const [key, value] of Object.entries(content)) {
    str += `${key}: ${value}\n`;
  }

  return str;
}

export default CopyToClipboardButton;
