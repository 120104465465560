import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import styles from "../../styles/menuStyles";

const useStyles = makeStyles(styles);

function FirmwareDropdown({
  rides,
  firmware,
  hwRevision,
  jetpack,
  type,
  onChange,
}) {
  const classes = useStyles();

  const sessionHasCorrectJetpack = (session) => {
    if (jetpack) {
      const foundJetpack = session?.jetpackId ?? session?.jetpackID;

      if (!foundJetpack?.includes(jetpack)) {
        return false;
      }
    }

    return true;
  };

  const firmwares = {
    g3: [
      ...new Set(
        rides.sessions
          .filter((session) => {
            if (hwRevision) {
              if (!session?.hardwareRevision?.includes(hwRevision)) {
                return false;
              }
            }

            if (!sessionHasCorrectJetpack(session)) {
              return false;
            }

            return true;
          })
          .map(
            (session) =>
              session?.versions?.firmware?.[0] ??
              session?.versions?.fw ??
              session?.versions?.bms
          )
          .filter((fw) => fw)
      ),
    ].sort((a, b) => b.localeCompare(a)), // sort alphabetically desc
  };

  let firmwaresToDisplay = [];

  if (type) {
    firmwaresToDisplay = firmwares?.[type?.toLowerCase()] ?? [];
  } else {
    firmwaresToDisplay = Object.values(firmwares)?.reduce(
      (acc, curr) => [...acc, ...curr],
      []
    );
  }

  return (
    <FormControl>
      <InputLabel shrink id="select-firmware-placeholder-label-label">
        FW
      </InputLabel>
      <Select
        labelId="select-firmware-placeholder-label-label"
        id="select-firmware-placeholder-label"
        value={firmware}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        displayEmpty
        className={classes.selectEmpty}
      >
        <MenuItem value="">All</MenuItem>
        {firmwaresToDisplay.map((fw) => (
          <MenuItem key={fw} value={fw}>
            {fw}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default FirmwareDropdown;
