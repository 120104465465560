import React, { useState } from 'react';

import SearchPanel from './SearchPanel';
import Previews from './Previews';

function Menu(props) {
  const [loading, setLoading] = useState(false);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <div style={{ width: '100%' }}>
        <SearchPanel {...props} loading={loading} />
      </div>
      <div style={{ flexGrow: 1, height: '100%' }}>
        <Previews setLoading={setLoading} />
      </div>
    </div>
  );
}

export default Menu;
