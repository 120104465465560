import React, { useState } from 'react';
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import Code from '@material-ui/icons/Code';
import Check from '@material-ui/icons/Check';
import Loop from '@material-ui/icons/Loop';

// Use these when upgraded mui to v5
//import { Downloading } from '@material-ui/icons';
//import { Download } from '@material-ui/icons';
//import { DownloadDone } from '@material-ui/icons';

function Icon({ loading, success }) {
  if (loading) {
    return <Loop />;
  }

  if (success) {
    return <Check />;
  }

  return <Code />;
}

function DownloadJson({ fileName, data }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    setSuccess(false);
    const json = 'data:application/json;charset=utf-8,' + JSON.stringify(data);
    const downloadUrl = encodeURI(json);
    const link = document.createElement('a');

    link.setAttribute('href', downloadUrl);
    link.setAttribute('download', `${fileName}.json`);
    link.click();
    link.remove();

    setLoading(false);
    setSuccess(true);
  };

  return (
    <Tooltip title="Download JSON">
      <IconButton
        color="primary"
        disabled={loading}
        onClick={handleClick}
      >
        <Icon loading={loading} success={success} />
      </IconButton>
    </Tooltip>
  );
}

export default DownloadJson;
