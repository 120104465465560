const constants = {
  ENDPOINT_RUNS: `${process.env.REACT_APP_BACKEND_URL}/rides/runs`,
  ENDPOINT_BATTERIES: `${process.env.REACT_APP_BACKEND_URL}/v1/battery-diagnostics`,
  ENDPOINT_SESSIONS: `${process.env.REACT_APP_BACKEND_URL}/v1/sessions`,

  SET_JETPACK: "setJetpack",
  SET_BATTERY: "setBattery",
  SET_RIDER: "setRider",
  SET_DATES: "setDates",
  SET_SESSION: "setSession",
  SET_INTERVAL: "setInterval",
  RESET_INTERVAL: "resetInterval",
  SET_INDEX: "setIndex",

  SET_JETPACK_SEARCH: "setJetpackSearch",
  SET_BATTERY_SEARCH: "setBatterySearch",
  SET_RIDER_SEARCH: "setRiderSearch",
  SET_FROM_DATE_SEARCH: "setFromDateSearch",
  SET_TO_DATE_SEARCH: "setToDateSearch",
  SET_USE_DATE_RANGE: "setUseDateRange",
  SEARCH: "search",
  RESET: "reset",

  LOCALSTORAGE_DATACARD_FILTER: "dataCardFilter",

  SENSOR_WARNINGS: [
    {
      key: "warnings",
      alias: "ESC warnings",
      values: [
        { name: "Low voltage", color: "#D72638", value: 1 << 0 },
        { name: "High current", color: "#F49D37", value: 1 << 1 },
        { name: "Controller overheated", color: "#0EB6DB", value: 1 << 2 },
        { name: "Battery overheated", color: "#140F2D", value: 1 << 3 },
        { name: "Motor overheated", color: "#F22B29", value: 1 << 4 },
      ],
    },
    {
      key: "failures",
      alias: "ESC failures",
      values: [
        { name: "DMS Off", color: "#F49D37", value: (1 << 0) + (1 << 1) }, // input signal missing + waiting for throttle = DMS off
        { name: "Power off request", color: "#0EB6DB", value: 1 << 2 },
        { name: "Memory error", color: "#140F2D", value: 1 << 3 },
        {
          name: "Power off request settings change",
          color: "#F22B29",
          value: 1 << 4,
        },
        { name: "Overcurrent error", color: "#587291", value: 1 << 5 },
        { name: "Internal power source", color: "#0CF574", value: 1 << 6 },
        {
          name: "Power off request HAL learning procedure completed successfully",
          color: "#ABFF4F",
          value: 1 << 7,
        },
        { name: "Battery temp sensor error", color: "#C6D4FF", value: 1 << 8 },
        { name: "Motor temp sensor error", color: "#12664F", value: 1 << 9 },
        { name: "Other HW error", color: "#FF3366", value: 1 << 15 },
      ],
    },
    {
      key: "warning",
      alias: "BMS warnings",
      values: [
        { name: "Low capacity", color: "#D72638", value: 1 << 0 },
        { name: "Low voltage", color: "#F49D37", value: 1 << 1 },
        { name: "High current", color: "#0EB6DB", value: 1 << 2 },
        { name: "Overheat AKU", color: "#140F2D", value: 1 << 3 },
        { name: "Overheat EXT", color: "#F22B29", value: 1 << 4 },
        { name: "Overheat CPU", color: "#587291", value: 1 << 5 },
      ],
    },
  ],
};

export default constants;
