import { useLocation } from 'react-router-dom';

function useQueryParams() {
  const query = new URLSearchParams(useLocation().search).toString();

  if (query) {
    return `?${query}`;
  }

  return '';
}

export default useQueryParams;