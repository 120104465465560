import { useState, useEffect } from 'react';
import { getApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut as signOutFirebase,
} from "firebase/auth";
import { useAuthState as useFirebaseAuthState } from "react-firebase-hooks/auth";

function useAuth() {
  const app = getApp();
  const auth = getAuth(app);

  const [user, loading, error] = useFirebaseAuthState(auth);

  const signIn = () => {
    let provider = new GoogleAuthProvider();

    provider.addScope("email");

    return signInWithPopup(auth, provider);
  };

  const signOut = () => signOutFirebase(auth);

  return {
    user,
    loading,
    error,
    signIn,
    signOut,
  };
}

async function fetchToken(user, setToken, setLoading) {
  setLoading(true);
  const token = await user?.getIdToken();

  setToken(token);
  setLoading(false);
}

function useToken() {
  const app = getApp();
  const auth = getAuth(app);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(null);

  const user = auth.currentUser;

  useEffect(() => {
    fetchToken(user, setToken, setLoading);
  }, [user]);

  return [token, loading];
}

export {
  useAuth,
  useToken,
};
