import React from 'react';
import {
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

function DatePickers(props) {
  const {
    fromDate,
    toDate,
    enableNoDateRangeButton,
    enableDateRange,
    onChangeEnableDateRange,
    onChange,
    controls = true,
    time,
  } = props;

  const format = time ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
  const defaultFromDate = moment().add(-1, 'week').set('hour', 0).set('minute', 0).format(format);
  const defaultToDate = moment().set('hour', 23).set('minute', 59).format(format);

  const handleChange = (e) => {
    const checked = e.target.checked;
    onChangeEnableDateRange(checked);

    if (checked) {
      const newFromDate = fromDate
        ? fromDate
        : defaultFromDate;
      const newToDate = toDate
        ? toDate
        : defaultToDate;

      onChange(newFromDate, newToDate);
    }
  };


  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {
            controls && (
              <Grid item xs={12} md={3}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={enableDateRange}
                        disabled={!enableNoDateRangeButton}
                        onChange={handleChange}
                        name="Enable date range"
                        color="primary"
                      />
                    }
                    label="Enable date range"
                  />
                </FormGroup>
              </Grid>
            )
          }
          <Grid item xs={6} md={4}>
            {
              time
                ? (
                  <KeyboardDateTimePicker
                    disabled={!enableDateRange}
                    disableToolbar
                    ampm={false}
                    variant="inline"
                    format="yyyy-MM-dd HH:mm"
                    margin="normal"
                    label="From"
                    value={new Date(fromDate || defaultFromDate)}
                    onChange={(d) => onChange(d, toDate)}
                    KeyboardButtonProps={{
                      'aria-label': 'change from date',
                    }}
                    style={{ width: '100%' }}
                  />
                )
                : (
                  <KeyboardDatePicker
                    disabled={!enableDateRange}
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    label="From"
                    value={new Date(fromDate || defaultFromDate)}
                    onChange={(d) => onChange(d, toDate)}
                    KeyboardButtonProps={{
                      'aria-label': 'change from date',
                    }}
                    style={{ width: '100%' }}
                  />
                )
            }
          </Grid>
          <Grid item xs={6} md={4}>
            {
              time
                ? (
                  <KeyboardDateTimePicker
                    disabled={!enableDateRange}
                    disableToolbar
                    ampm={false}
                    variant="inline"
                    format="yyyy-MM-dd HH:mm"
                    margin="normal"
                    label="To"
                    value={new Date(toDate || defaultToDate)}
                    minDate={new Date(fromDate || defaultFromDate)}
                    minDateMessage="Must be greater or equal to start date"
                    onChange={(d) => onChange(fromDate, d)}
                    KeyboardButtonProps={{
                      'aria-label': 'change to date',
                    }}
                    style={{ width: '100%' }}
                  />
                )
                : (
                  <KeyboardDatePicker
                    disabled={!enableDateRange}
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    label="To"
                    value={new Date(toDate || defaultToDate)}
                    minDate={new Date(fromDate || defaultFromDate)}
                    minDateMessage="Must be greater or equal to start date"
                    onChange={(d) => onChange(fromDate, d)}
                    KeyboardButtonProps={{
                      'aria-label': 'change to date',
                    }}
                    style={{ width: '100%' }}
                  />
                )
            }
          </Grid>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default DatePickers;
