import React from "react";

import SearchPanel from "./SearchPanel";
import Previews from "./Previews";

type Props = {
  query?: string;
  fromDate?: string;
  toDate?: string;
  loading?: boolean;
};

function Menu(props: Props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ width: "100%" }}>
        <SearchPanel {...props} />
      </div>
      <div style={{ flexGrow: 1, height: "100%" }}>
        <Previews />
      </div>
    </div>
  );
}

export default Menu;
