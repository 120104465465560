import React from 'react';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from '@material-ui/core';
import { decamelize } from '../../../../utils';

function formatValueByKey(key, value) {
  if (value === null || value === undefined) {
    return '-';
  }

  key = key.toLowerCase();

  if (key.includes('temp')) {
    return `${value}°C`;
  }

  if (key.includes('direction')) {
    return `${value}°`;
  }

  if (key.includes('speed')) {
    return `${value}m/s`;
  }

  if (key.includes('cover')) {
    return `${value}%`;
  }

  if (key.includes('humidity')) {
    return `${value}%`;
  }

  if (key.includes('precipitation')) {
    return `${value}kg/m²`;
  }

  if (key.includes('height')) {
    return `${value}m`;
  }

  if (key.includes('pressure')) {
    return `${value}hPa`;
  }

  return value;
}

function WeatherPanel({ weather }) {
  return (
    <Card>
      <CardHeader title="Weather" />
      <CardContent>
        {
          weather
            ? (
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    {
                      Object.entries(weather)
                        .filter(([key]) => key !== 'time')
                        .map(([key, value]) => {
                          return (
                            <TableRow key={key}>
                              <TableCell>{decamelize(key)}</TableCell>
                              <TableCell>{formatValueByKey(key, value)}</TableCell>
                            </TableRow>
                          )
                        })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            )
            : (
              <Typography variant="h6">No weather data found</Typography>
            )
        }
      </CardContent>
    </Card>
  );
}

export default WeatherPanel;
