import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  Bar,
  LabelList,
} from 'recharts';
import Card from "../../components/Card";
import CustomTooltip from '../../components/Chart/CustomTooltip';
import { round } from '../../utils';

function CustomizedLabel({ x, y, width, value, index, data, unit }) {
  const isBalancing = data?.[index]?.balancing;

  return (
    <g>
      <text x={x + width / 2} y={y - 5} fill={isBalancing ? '#ff0000' : '#fff'} textAnchor="middle" dominantBaseline="top">
        {round(2)(value)}{unit}
      </text>
    </g>
  );
}

function VoltageChart({ voltages, balancing, delta, title, action }) {
  const data = voltages?.map((cell, index) => ({
    cellRow: `Row ${index + 1}`,
    voltage: cell,
    balancing: Boolean(balancing[index]),
  }));

  const roundedDelta = Math.round(delta * 100) / 100;

  return (
    <Card
      title={`${title || "Voltage"}`}
      subheader={`Δ${roundedDelta}V`}
      headerAction={action}
    >
      <ResponsiveContainer width="100%" height={200}>
        <BarChart unit="V" data={data}>
          <XAxis dataKey="cellRow" stroke="#ffffff" />
          <YAxis domain={[2.5, 4.5]} unit="V" stroke="#ffffff" />
          <ChartTooltip
            content={<CustomTooltip extraDataKeys={["balancing"]} />}
          />
          <Bar dataKey="voltage" fill="#0eb6bd">
            <LabelList
              content={<CustomizedLabel data={data} />}
              dataKey="voltage"
              unit="V"
              position="top"
              stroke="#ffffff"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default VoltageChart;
