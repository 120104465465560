import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router";
import moment from "moment";
import SearchField from "../../components/Search/SearchField";
import DatePickers from "../../components/Search/DatePickers";
import styles from "../styles/menuStyles";
import { getQueryParams } from "../../utils";

const useStyles = makeStyles(styles);

function SearchPanel(props) {
  const navigate = useNavigate();
  const { jetpack, battery, rider, fromDate, toDate, sessionId } = props;
  const classes = useStyles();

  const [noDateRangeButtonEnabled, setNoDateRangeButtonEnabled] = useState(
    jetpack || battery || rider
  );
  const [enableDateRange, setEnableDateRange] = useState(
    Boolean(fromDate) || Boolean(toDate)
  );
  const [search, setSearch] = useState({
    jetpack,
    battery,
    rider,
    fromDate,
    toDate,
  });

  useEffect(() => {
    if (search.jetpack || search.battery || search.rider) {
      setNoDateRangeButtonEnabled(true);
    } else {
      setNoDateRangeButtonEnabled(false);
    }
  }, [search.jetpack, search.battery, search.rider]);

  useEffect(() => {
    if (!enableDateRange) {
      search.fromDate = null;
      search.toDate = null;
    }
  }, [enableDateRange, search]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (sessionId) {
      navigate(`/rides/${sessionId}${getQueryParams({}, search)}`);
    } else {
      navigate(`/rides/${getQueryParams({}, search)}`);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1} style={{ padding: 10 }}>
        <Grid item xs={12}>
          <Typography variant="h6">Search Options</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <SearchField
            helperText="Jetpack ID"
            value={search.jetpack ?? ""}
            onChange={(value) => {
              setSearch({
                ...search,
                jetpack: value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SearchField
            helperText="Battery ID"
            value={search.battery ?? ""}
            onChange={(value) => {
              setSearch({
                ...search,
                battery: value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SearchField
            helperText="Rider ID"
            value={search.rider ?? ""}
            onChange={(value) => {
              setSearch({
                ...search,
                rider: value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <DatePickers
              enableNoDateRangeButton={noDateRangeButtonEnabled}
              enableDateRange={enableDateRange}
              fromDate={search.fromDate}
              toDate={search.toDate}
              onChangeEnableDateRange={(checked) => {
                setEnableDateRange(checked);
              }}
              onChange={(fromDate, toDate) => {
                setSearch({
                  ...search,
                  fromDate: moment(fromDate).format("YYYY-MM-DD"),
                  toDate: moment(toDate).format("YYYY-MM-DD"),
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={() => setSearch({ fromDate, toDate })}
                className={classes.button}
                component={Link}
                to={`/rides/${sessionId || ""}`}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default SearchPanel;
