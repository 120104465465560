import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { LinearProgress, Button } from "@material-ui/core";
import {
  DataGrid,
  GridOverlay,
  getGridStringOperators,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/styles";
import qs from "query-string";
import prettyMilliseconds from "pretty-ms";
import httpStatusCodes from "http-status-codes";

import { useBatterySearchEntries } from "../hooks/useBatteryDiag";

const useStyles = makeStyles(() => ({
  dataGrid: {
    "& .MuiDataGrid-row": {
      cursor: "pointer",
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  },
}));

function LoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

function ErrorOverlay(props) {
  const { name, retries, setRetries } = props;

  let httpError = "";

  try {
    httpError = httpStatusCodes.getStatusText(name);
  } catch (e) {
    httpError = "Unknown error";
  }

  return (
    <GridOverlay>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <code>{httpError}</code>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setRetries(retries + 1)}
        >
          Retry
        </Button>
      </div>
    </GridOverlay>
  );
}

function NoRowsOverlay() {
  return (
    <GridOverlay style={{ zIndex: 5 }}>
      <div
        style={{
          display: "flex",
          flexFlow: "column wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <code style={{ maxWidth: "75%" }}>No data</code>
        <code style={{ marginTop: 15, maxWidth: "75%" }}>
          If you are looking for diagnostics from before 2023-03-27 you can try
          looking in the{" "}
          <a
            style={{ color: "white" }}
            href="https://legacy-radinn-analytics.web.app/batteries"
          >
            legacy web app
          </a>
        </code>
      </div>
    </GridOverlay>
  );
}

function Previews({ setLoading }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [limit, setLimit] = useState(25);
  const activeSessionId = location.pathname
    .split("/")
    .filter((p) => p && p !== "batteries" && p !== "sessions")
    .at(-1);
  const [filter, setFilter] = useState([]);
  const params = qs.parse(location.search);

  const [retries, setRetries] = useState(0);
  const [page, setPage] = useState(0);
  const [data, loading, error] = useBatterySearchEntries(
    { ...params, limit, page },
    filter,
    retries
  );
  const [selectedRows, setSelectedRows] = useState([activeSessionId]);

  useEffect(() => {
    if (!loading) {
      setSelectedRows([activeSessionId]);
    }
  }, [loading, limit, activeSessionId, setSelectedRows]);

  const formattedData =
    data?.sessions?.map((session) => ({
      ...session,
      sessionStartDate: new Date(session.sessionStartDate).toLocaleString(
        "sv-SE"
      ),
      duration: session.duration
        ? prettyMilliseconds(session.duration, { compact: true })
        : "",
      firmware: session.versions?.vbms ?? session.versions?.bms,
    })) ?? [];

  const defaultOperators = getGridStringOperators();
  const equalOperator = defaultOperators.filter(
    ({ value }) => value === "equals"
  );
  const isNotEmptyOperator = defaultOperators.filter(
    ({ value }) => value === "isNotEmpty"
  );
  const columns = [
    {
      field: "sessionStartDate",
      headerName: "Start",
      width: 155,
      filterOperators: isNotEmptyOperator,
      renderCell: (content) => (
        <Link
          style={{ textDecoration: "none", color: "white" }}
          to={`/batteries/sessions/${content.row.id}${location.search}`}
        >
          {content?.value}
        </Link>
      ),
    },
    {
      field: "batteryId",
      headerName: "Battery",
      width: 145,
      filterOperators: equalOperator,
    },
    {
      field: "type",
      headerName: "Type",
      width: 110,
      filterOperators: equalOperator,
    },
    {
      field: "firmware",
      headerName: "FW",
      width: 105,
      filterOperators: equalOperator,
    },
    {
      field: "duration",
      headerName: "Length",
      width: 125,
      filterOperators: isNotEmptyOperator,
    },
  ];

  return (
    <DataGrid
      style={{ height: "100%" }}
      className={classes.dataGrid}
      density="compact"
      hideFooterSelectedRowCount
      loading={loading}
      components={{
        LoadingOverlay: LoadingOverlay,
        ErrorOverlay: ErrorOverlay,
        NoRowsOverlay: NoRowsOverlay,
      }}
      componentsProps={{ errorOverlay: { retries, setRetries } }}
      error={error}
      columns={columns}
      rows={formattedData ?? []}
      rowCount={data?.total ?? 0}
      rowsPerPageOptions={[10, 25, 50]}
      paginationMode="server"
      filterMode="server"
      pagination
      pageSize={limit}
      page={page}
      onRowClick={(e) => {
        navigate(`/batteries/sessions/${e.row.id}${location.search}`);
      }}
      onPageSizeChange={(newPageSize) => {
        setLimit(newPageSize);
        setPage(0);
      }}
      onPageChange={(newPage) => {
        setPage(newPage);
      }}
      selectionModel={selectedRows}
      onFilterModelChange={(model) => setFilter(model)}
    />
  );
}

export default Previews;
