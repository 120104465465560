import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import useZoom from "../../hooks/useZoom";
import CustomTooltip from "../../components/Chart/CustomTooltip";
import Card from "../../components/Card";

const useStyles = makeStyles({
  chart: {
    fontFamily: "Roboto",
    userSelect: "none",
    overflow: "visible",
    marginTop: "1%",
    "& .recharts-wrapper": {
      margin: "auto",
    },
    "& .recharts-default-tooltip": {
      textAlign: "center",
      "& p": {
        fontWeight: 700,
        textDecoration: "underline",
      },
    },
  },
});

function ChargeVoltageLineChart({
  XDomain,
  setXDomain,
  entries,
  currentIndex,
  setCurrentIndex
}) {
  const classes = useStyles();
  const {
    zoomStart,
    tempZoomEnd,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove
  } = useZoom(setXDomain);
  const currentTimestamp = new Date(
    entries[currentIndex]?.timestamp
  ).valueOf();
  const data = entries.map((entry) => {
    if (!entry?.energy?.current && !entry?.energy?.currentStm) {
      return {
        timestamp: new Date(entry.timestamp).valueOf(),
      };
    }

    return {
      chargeVoltage: entry.energy.chargeVoltage,
      chargeVoltage2: entry.energy.chargeVoltage2,
      timestamp: new Date(entry.timestamp).valueOf(),
    };
  });

  return (
    <Card title="Charge voltage">
      <ResponsiveContainer className={classes.chart} width="100%" height={200}>
        <LineChart
          data={data}
          syncId="session"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onClick={(e) => {
            const index = entries.findIndex(
              (item) => new Date(item.timestamp).valueOf() === e?.activeLabel
            );

            setCurrentIndex(index);
          }}
        >
          <Line
            dataKey="chargeVoltage"
            name="Charge voltage"
            dot={false}
            stroke="#0eb6bd"
            connectNulls={true}
          />
          <Line
            dataKey="chargeVoltage2"
            name="Charge voltage 2"
            dot={false}
            stroke="#0eb6bd"
            connectNulls={true}
          />
          <ReferenceLine
            x={currentTimestamp}
            stroke="white"
          />
          {zoomStart && tempZoomEnd && (
            <ReferenceArea
              x1={zoomStart}
              x2={tempZoomEnd}
              strokeOpacity={0}
              fillOpacity={0.1}
            />
          )}
          <Tooltip
            content={
              <CustomTooltip
                unit="V"
                labelFormatter={(label) => new Date(label).toLocaleString()}
              />
            }
          />
          <XAxis
            type="number"
            domain={XDomain}
            dataKey="timestamp"
            textAnchor="end"
            allowDataOverflow={true}
            tick={true}
            stroke="#FFFFFF"
            tickFormatter={(ts) => new Date(ts).toLocaleTimeString('sv-SE')}
          />
          <YAxis
            stroke="#FFFFFF"
            type="number"
            domain={[0, "dataMax + 10"]}
            unit="V"
            tick
            tickFormatter={(v) => v.toFixed(0)}
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default ChargeVoltageLineChart;
