import React from 'react';
import {
  Dialog,
  DialogTitle,
  Grid,
} from '@material-ui/core';

function SettingsDialog({ open, onClose, children }) {
  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="settings-dialog" style={{ overflow: 'visible' }}>
      <DialogTitle id="settingsDialogTitle">Map Settings</DialogTitle>
      <Grid container spacing={16} style={{ paddingTop: 16, paddingBottom: 16, paddingLeft: 24, paddingRight: 24 }}>
        <Grid item xs>
          { children }
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default SettingsDialog;

