import React from 'react';
import Chip from '@material-ui/core/Chip';
import ErrorIcon from '@material-ui/icons/Error';

function GpsStatus({ session }) {
  if (session?.hardwareRevision === '0x938F3E4D v1.6') {
    return (
      <Chip
        variant="outlined"
        color="default"
        icon={<ErrorIcon />}
        label="No GPS module"
      />
    );
  }

  if (session?.place) {
    return session.place;
  }

  return (
    <Chip
      variant="outlined"
      color="default"
      icon={<ErrorIcon />}
      label="Missing data"
    />
  );
}

export default GpsStatus;
