import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import styles from "../../styles/menuStyles";

const useStyles = makeStyles(styles);

function TypeDropdown({ rides, jetpack, type, onChange }) {
  const classes = useStyles();

  const sessionHasCorrectJetpack = (session) => {
    if (jetpack) {
      if (!session?.jetpackId?.includes(jetpack)) {
        return false;
      }
    }

    return true;
  };

  const jetpackTypes = [
    ...new Set(
      [...(rides?.headers ?? []), ...(rides?.sessions ?? [])]
        .filter(sessionHasCorrectJetpack)
        .map((session) => session?.deviceType ?? session?.jetpackType ?? "-")
        .filter((type) => type)
    ),
  ];

  return (
    <FormControl>
      <InputLabel shrink id="select-type-placeholder-label-label">
        Type
      </InputLabel>
      <Select
        labelId="select-type-placeholder-label-label"
        id="select-type-placeholder-label"
        value={type}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        displayEmpty
        className={classes.selectEmpty}
      >
        <MenuItem value="">All</MenuItem>
        {jetpackTypes.map((type) => (
          <MenuItem key={type} value={type}>
            {type.toUpperCase()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default TypeDropdown;
