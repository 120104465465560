import React from "react";
import GoogleMap from "../../components/GoogleMap";
import { GpsEntry } from "@radinnab/core-types";
import Error from "../../components/Error";

type Props = {
  coords: GpsEntry[];
  highlightedIndex?: number;
  onClick?: (coord: GpsEntry) => void;
};

function Map(props: Props) {
  let { coords, onClick } = props;

  coords = coords.map((coord) => {
    if (Boolean(coord.latitude) && Boolean(coord.longitude)) {
      return coord;
    }

    return {
      ...coord,
      latitude: undefined,
      longitude: undefined,
    };
  });

  const validCoords = coords.filter((coord) => {
    return Boolean(coord.latitude) && Boolean(coord.longitude);
  });

  let firstValidCoordIndex = 0;
  let firstValidCoord = coords.find((coord, index) => {
    if (Boolean(coord.latitude) && Boolean(coord.longitude)) {
      firstValidCoordIndex = index;
      return true;
    }

    return false;
  });

  if ((validCoords?.length ?? 0) === 0 || !firstValidCoord) {
    return <Error message="No GPS signal" />;
  }

  // Interpolate by setting invalid coords to the first valid coord
  for (let i = 0; i < coords.length; i++) {
    if (i < firstValidCoordIndex) {
      coords[i].latitude = firstValidCoord.latitude;
      coords[i].longitude = firstValidCoord.longitude;
    }
  }

  let currentPos = coords[0];

  if (props.highlightedIndex !== undefined) {
    currentPos = coords[props.highlightedIndex];
  }

  return (
    <GoogleMap
      apiKey={process.env.REACT_APP_MAPS_API_KEY}
      data={coords.map((coord) => ({
        lat: coord.latitude,
        lng: coord.longitude,
      }))}
      centerLat={coords[0].latitude}
      centerLng={coords[0].longitude}
      currentPos={{
        lat: currentPos.latitude,
        lng: currentPos.longitude,
      }}
      onClick={(index: number) => {
        if (onClick) {
          onClick(coords[index]);
        }
      }}
    />
  );
}

export default Map;
