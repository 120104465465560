import React, { useState } from "react";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import SettingsBackupRestore from "@material-ui/icons/SettingsBackupRestore";
import Card from "../../components/Card";
import VoltageLineChart from "./VoltageLineChart";
import TemperatureLineChart from "./TemperatureLineChart";
import HumidityLineChart from "./HumidityLineChart";
import CurrentLineChart from "./CurrentLineChart";
import SOCLineChart from "./SOCLineChart";
import SOHLineChart from "./SOHLineChart";
import SystemConnectorVoltageLineChart from "./SystemConnectorVoltageLineChart";
import ChargeVoltageLineChart from "./ChargeVoltageLineChart";

function ResetZoomButton({ onClick, disabled }) {
  if (disabled) {
    return (
      <IconButton disabled>
        <SettingsBackupRestore />
      </IconButton>
    );
  }

  return (
    <Tooltip title="Reset zoom">
      <IconButton onClick={onClick} disabled={disabled}>
        <SettingsBackupRestore />
      </IconButton>
    </Tooltip>
  );
}

function GraphsContainer({ data, currentIndex, setCurrentIndex }) {
  const startTime = new Date(data.at(0)?.timestamp).valueOf();
  const endTime = new Date(data.at(-1)?.timestamp).valueOf();
  const [domain, setDomain] = useState([
    startTime.valueOf(),
    endTime.valueOf(),
  ]);

  const handleResetZoom = () => {
    setDomain([startTime, endTime]);
  };

  const hasCellVoltages =
    data.filter((e) => e.energy?.cellVoltages?.length > 0).length > 0;
  const hasCurrent = data.filter((e) => e.energy?.current).length > 0;
  const hasChargeVoltage =
    data.filter((e) => e.energy?.chargeVoltage).length > 0;
  const hasSoC = data.filter((e) => e.energy?.soc).length > 0;
  const hasSoH = data.filter((e) => e.energy?.soh).length > 0;
  const hasCellTemps =
    data.filter((e) => (e.environment?.temperatures?.cells ?? e.environment?.cellTemps)?.length > 0).length > 0;
  const hasHumidity = data.filter((e) => e.environment?.humidity).length > 0;
  const hasSystemConnectorVoltage =
    data.filter((e) => e.environment?.vsense ?? e.environment?.moist).length >
    0;

  if (!data || data.length === 0) {
    return null;
  }

  if (
    !hasCellVoltages &&
    !hasCurrent &&
    !hasChargeVoltage &&
    !hasSoC &&
    !hasCellTemps &&
    !hasHumidity &&
    !hasSystemConnectorVoltage
  ) {
    return null;
  }

  return (
    <Card
      title="Graphs"
      headerAction={
        <ResetZoomButton
          onClick={handleResetZoom}
          disabled={
            domain[0] === startTime.valueOf() && domain[1] === endTime.valueOf()
          }
        />
      }
    >
      <Grid container spacing={1}>
        {hasCellVoltages && (
          <Grid item xs={12} xl={6}>
            <VoltageLineChart
              XDomain={domain}
              setXDomain={setDomain}
              entries={data}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          </Grid>
        )}
        {hasCurrent && (
          <Grid item xs={12} xl={6}>
            <CurrentLineChart
              XDomain={domain}
              setXDomain={setDomain}
              entries={data}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          </Grid>
        )}
        {hasChargeVoltage && (
          <Grid item xs={12} xl={6}>
            <ChargeVoltageLineChart
              XDomain={domain}
              setXDomain={setDomain}
              entries={data}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          </Grid>
        )}
        {hasSoC && (
          <Grid item xs={12} xl={6}>
            <SOCLineChart
              XDomain={domain}
              setXDomain={setDomain}
              entries={data}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          </Grid>
        )}
        {hasSoH && (
          <Grid item xs={12} xl={6}>
            <SOHLineChart
              XDomain={domain}
              setXDomain={setDomain}
              entries={data}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          </Grid>
        )}
        {hasCellTemps && (
          <Grid item xs={12} xl={6}>
            <TemperatureLineChart
              XDomain={domain}
              setXDomain={setDomain}
              entries={data}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          </Grid>
        )}
        {hasHumidity && (
          <Grid item xs={12} xl={6}>
            <HumidityLineChart
              XDomain={domain}
              setXDomain={setDomain}
              entries={data}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          </Grid>
        )}
        {hasSystemConnectorVoltage && (
          <Grid item xs={12} xl={6}>
            <SystemConnectorVoltageLineChart
              XDomain={domain}
              setXDomain={setDomain}
              entries={data}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          </Grid>
        )}
      </Grid>
    </Card>
  );
}

export default GraphsContainer;
