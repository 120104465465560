import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  LinearProgress,
  Grid,
} from "@material-ui/core";
import { useBatteryDiagSession } from "../hooks/useBatteryDiag";
import Error from "../components/Error";
import SnapshotContainer from "./content/SnapshotContainer";
import MetadataCard from "./content/MetadataCard";
import SessionSummaryCard from "./content/SessionSummaryCard";
import GraphsContainer from "./content/GraphsContainer";

function SessionContainer() {
  const { sessionId } = useParams();
  const [data, loading, error] = useBatteryDiagSession(sessionId);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentData, setCurrentData] = useState(null);

  useEffect(() => {
    if (data?.entries) {
      setCurrentIndex(data.entries.length - 1);
    }
  }, [data]);

  useEffect(() => {
    if (data?.entries) {
      setCurrentData(data.entries[currentIndex]);
    }
  }, [data, currentIndex]);

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Error message={`Failed to fetch session data: ${error}`} />;
  }

  if (!data) {
    return <Error message={`No session data found.`} />;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <MetadataCard data={data} />
      </Grid>
      <Grid item xs={12} md={6}>
        <SessionSummaryCard data={data} />
      </Grid>
      <Grid item xs={12}>
        <SnapshotContainer data={currentData} />
      </Grid>
      <Grid item xs={12}>
        <GraphsContainer
          data={data.entries}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      </Grid>
    </Grid>
  );
}

export default SessionContainer;
