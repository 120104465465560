import moment from 'moment';
import qs from 'query-string';

const getDateRange = (startDate, endDate, format='YYYY-MM-DD') => {
  var dateArray = [];
  const end = moment(endDate);
  var currentDate = moment(startDate);

  while (currentDate <= end) {
    dateArray.push(currentDate.format(format));
    currentDate = currentDate.add(1, 'days');
  }

  return dateArray;
};

function compose(...fns) {
  return fns.reduceRight((prevFn, nextFn) =>
    (...args) => nextFn(prevFn(...args)),
    value => value,
  );
}

function getQueryParams(prevState, updatedState = {}) {
  const state = {
    ...prevState,
    ...updatedState,
  };
  const queries = Object.entries(state)
    .filter(([ key, value ]) => value)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  if (queries.length === 0) {
    return '';
  }

  return `?${qs.stringify(queries)}`;
}

function capitalize(str) {
  return `${str.substr(0, 1).toUpperCase()}${str.substr(1, str.length)}`;
}

function decamelize(str, separator = ' '){
  // converts a camelCase string to a normal readable string with capital first letter
	const formatted = str
        .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
        .toLowerCase();

  return capitalize(formatted);
}

function getUnitByKey(key) {
  const lower = key.toLowerCase();
  if (lower.includes('voltage') && !lower.includes('adress')) {
    return 'V';
  }

  if (lower.includes('current')) {
    return 'A';
  }

  if (lower.includes('temp')) {
    return '°C';
  }

  if (lower.includes('output')) {
    return '%';
  }

  if (lower.includes('gpsspeed')) {
    return 'km/h';
  }

  if (lower.includes('humidity')) {
    return '%';
  }

  if (lower.includes('geofencereduction')) {
    return '‰';
  }

  return '';
}

function getGPSStatus(fw) {
  if (fw >= 178) {
    return {
      name: 'gpsStatus',
      values: {
        0: 'Initializing',
        1: 'No signal',
        2: 'Signal OK',
        3: 'Waiting for fix',
      },
    };
  }

  return {
    name: 'gpsStatus',
    values: {
      0: 'No signal',
      1: 'Signal OK',
      2: 'Waiting for fix',
      3: 'Comm error',
    },
  };
}

function translateValue(key, value, version = null) {
  const items = [
    getGPSStatus(version),
    {
      name: 'geofenceAccessArea',
      values: {
        0: 'Restricted',
        1: 'Allowed',
      },
    },
  ];

  if (items.map(item => item.name).includes(key)) {
    const foundItem = items.find(item => item.name === key);

    if (foundItem && foundItem.values) {
      return foundItem.values[value];
    }

    return foundItem.value;
  }

  return value;
}

function getDomain(key) {
  const items = {
    gpsStatus: [0, 1],
    geofenceAccessArea: [0, 1],
  };

  return items[key];
}

function round(decimals) {
  return (value) => {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  };
}

function median(numbers) {
  const sorted = numbers.sort((a, b) => a - b);
  const middle = sorted.length / 2;

  if (Number.isInteger(middle)) {
    return sorted[middle];
  }

  return (sorted[Math.floor(middle)] + sorted[Math.ceil(middle)]) / 2;
}

export {
  getDateRange,
  compose,
  getQueryParams,
  capitalize,
  decamelize,
  getUnitByKey,
  translateValue,
  getDomain,
  round,
  median,
};
