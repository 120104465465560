import React from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import { decamelize, getUnitByKey } from '../../utils';

function CustomTooltip(props) {
  const { label, labelFormatter, prefix = '', disableTooltipValue, unit, extraDataKeys = [] } = props;
  const currentData = props.payload;

  if (!currentData || currentData.length === 0) {
    return null;
  }

  const values = [...new Set(
    currentData
      .map(line => {
        const validKeys = Object.entries(line.payload)
          .filter(([key, value]) => (key === line.dataKey || extraDataKeys.includes(key)) && value !== null && value !== undefined)
          .map(([key, value]) => {
            if (disableTooltipValue) {
              return decamelize(key);
            }

            if (typeof value === 'boolean') {
              return `${decamelize(key)}`;
            }

            return `${decamelize(key)}: ${value}${unit ?? getUnitByKey(key)}`;
          });

        return validKeys;
      })
      .reduce((acc, curr) => [...acc, ...curr], [])
  )];

  return (
    <Paper style={{ padding: 5, maxWidth: 250 }}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{`${prefix}${labelFormatter?.(label) ?? label}`}</Typography>
        </Grid>
        {
          values.map((value) => (
            <Grid item xs={6} key={`${label}_${value}`}>
              <Typography variant="body1">{value}</Typography>
            </Grid>
          ))
        }
      </Grid>
    </Paper>
  );
}

export default CustomTooltip;
