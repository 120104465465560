import { useFetch } from 'use-http';
import qs from 'query-string';
import constants from '../rides/constants';

const { ENDPOINT_BATTERIES } = constants;

const options = {
  retries: 2,
  retryOn: ({ response }) => {
    return response && response.status >= 300
  },
  retryDelay: () => {
    return 1000;
  },
  cachePolicy: 'no-cache',
  persist: false
};

function useBatterySearchEntries(params, filter, retries) {
  if (filter) {
    params = {
      ...params,
      ...filter?.items?.reduce((acc, item) => {
        return {
          ...acc,
          [item.columnField]: item.value,
        };
      }, {}),
    };
  }
  const query = qs.stringify(params);

  const url = `${ENDPOINT_BATTERIES}/sessions${query && `?${query}`}`;

  const { loading, error, data = {} } = useFetch(
    url,
    options,
    [query, retries],
  );

  return [data?.data ?? {}, loading, error];
}

function useBatteryDiagSession(id) {
  let url = `${ENDPOINT_BATTERIES}/sessions/${id}`;

  const { loading, error, data } = useFetch(
    url,
    options,
    [id],
  );

  if (data?.data?.entries) {
    data.data.entries = data.data.entries.filter((entry) => Object.keys(entry).length > 1);
  }

  return [data?.data, loading, error];
}

export {
  useBatterySearchEntries,
  useBatteryDiagSession,
};
