import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import useZoom from "../../hooks/useZoom";
import CustomTooltip from "../../components/Chart/CustomTooltip";
import Card from "../../components/Card";

const useStyles = makeStyles({
  chart: {
    fontFamily: "Roboto",
    userSelect: "none",
    overflow: "visible",
    marginTop: "1%",
    "& .recharts-wrapper": {
      margin: "auto",
    },
    "& .recharts-default-tooltip": {
      textAlign: "center",
      "& p": {
        fontWeight: 700,
        textDecoration: "underline",
      },
    },
  },
});

function TemperatureLineChart({
  XDomain,
  setXDomain,
  entries,
  currentIndex,
  setCurrentIndex,
}) {
  const classes = useStyles();
  const {
    zoomStart,
    tempZoomEnd,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
  } = useZoom(setXDomain);

  const currentTimestamp = new Date(
    entries[currentIndex]?.timestamp
  ).valueOf();

  const temps = entries.map((entry) => {
    const cellTemps = entry?.environment?.temperatures?.cells ?? entry?.environment?.cellTemps;

    if (!cellTemps) {
      return null;
    }

    const temperatures = entry?.environment?.temperatures ?? entry?.environment;

    let result = {
      timestamp: new Date(entry.timestamp).valueOf(),
      ...Object.entries(temperatures)
        // Filter out cellTemps and non-temp sensors
        .filter(([key]) => !['cells', 'cellTemps', 'humidity', 'moist'].includes(key))
        // Convert to object
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {}),
    };

    for (const index in cellTemps) {
      result[`Cell ${parseInt(index, 10) + 1}`] = cellTemps[index];
    }

    return result;
  }).filter((entry) => entry !== null);

  const keys = [...new Set(...temps.map(entry => Object.keys(entry)
    .filter(key => !["timestamp"].includes(key))
    .flat()
  ))];

  return (
    <Card title="Temperatures">
      <ResponsiveContainer className={classes.chart} width="100%" height={200}>
        <LineChart
          data={temps}
          syncId="session"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onClick={(e) => {
            const index = entries.findIndex(
              (item) => new Date(item.timestamp).valueOf() === e?.activeLabel
            );

            setCurrentIndex(index);
          }}
        >
          {keys.map((key) => {
            return (
              <Line
                key={key}
                dataKey={key}
                name={key}
                dot={false}
                stroke="#0eb6bd"
                connectNulls={true}
              />
            );
          })}
          <ReferenceLine
            x={currentTimestamp}
            stroke="white"
          />
          {
            zoomStart && tempZoomEnd && (
              <ReferenceArea
                x1={zoomStart}
                x2={tempZoomEnd}
                strokeOpacity={0}
                fillOpacity={0.1}
              />
            )
          }
          <Tooltip
            content={
              <CustomTooltip
                unit="°C"
                labelFormatter={(label) => new Date(label).toLocaleString()}
              />
            }
          />
          <XAxis
            type="number"
            domain={XDomain}
            dataKey="timestamp"
            textAnchor="end"
            allowDataOverflow={true}
            tick={true}
            stroke="#FFFFFF"
            tickFormatter={(ts) => {
              return new Date(ts).toLocaleTimeString('sv-SE');
            }}
          />
          <YAxis
            stroke="#FFFFFF"
            type="number"
            domain={["dataMin - 10", "dataMax + 10"]}
            unit="°C"
            tick
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default TemperatureLineChart;
