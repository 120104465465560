import React from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Draggable } from 'react-beautiful-dnd';
import { useSessionData } from '../../../../../hooks/useRide';
import transformData from '../../transformData';
import { decamelize } from '../../../../../utils';
import EventGraphsContainer from './EventGraphsContainer';

function Event({
  session,
  startTime,
  endTime,
  event,
  draggableIndex,
  index,
  setIndex,
  timestamps,
}) {
  const [data, loading] = useSessionData(session, event);
  const graphs = transformData(event, data);

  return (
    <Draggable
      draggableId={event}
      index={draggableIndex}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Grid item xs={12} key={event} style={{ marginBottom: 8 }}>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              style={{ width: '100%' }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                {...provided.dragHandleProps}
              >
                <Typography>{decamelize(event)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {
                    loading
                      ? (
                        <Grid item xs={12}>
                          <LinearProgress variant="indeterminate" />
                        </Grid>
                      )
                      : (
                        <EventGraphsContainer
                          sessionId={session}
                          event={event}
                          graphs={graphs}
                          startTime={startTime}
                          endTime={endTime}
                          index={index}
                          setIndex={setIndex}
                          timestamps={timestamps}
                        />
                      )
                  }
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </div>
      )}
    </Draggable>
  );
}

export default Event;
