import React, { useState } from 'react';
import {
  Snackbar,
  Button,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CheckIcon from '@material-ui/icons/Check';

function DownloadButton({ url, fileName, title="Download raw log" }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);

  const download = async () => {
    setLoading(true);
    setSuccess(false);

    try {
      const res = await fetch(url);
      const blob = await res.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      let link = document.createElement('a');

      link.setAttribute('href', downloadUrl);
      link.setAttribute('download', `${fileName}.json`);
      link.click();
      link.remove();

      setSuccess(true);
    } catch (error) {
      console.warn(error);
      setToastOpen(true);
      setSuccess(false);
    }

    setLoading(false);
  }

  const handleClose = () => {
    setToastOpen(false);
  }

  const ButtonContent = () => {
    if (loading) {
      return <CircularProgress size={24} />;
    }

    if (success) {
      return <CheckIcon height={30} />;
    }

    return <DownloadIcon height={30} />;
  }

  return (
    <>
      <Tooltip title={title}>
        <div>
          <Button
            aria-label="save"
            color="primary"
            onClick={download}
            disabled={loading}
            style={{ paddingTop: 6, paddingBottom: 6 }}
          >
            <ButtonContent />
          </Button>
        </div>
      </Tooltip>
      <Snackbar open={toastOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Failed to download log :(
        </Alert>
      </Snackbar>
    </>
  );
}

export default DownloadButton;
