import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import styles from "../../styles/menuStyles";

const useStyles = makeStyles(styles);

const hwRevisionRegex = /v(\d+\.)?(\d+\.)?(\*|\d+)/;

function HwRevisionDropdown({
  rides,
  firmware,
  hwRevision,
  jetpack,
  type,
  onChange,
}) {
  const classes = useStyles();

  const sessionHasCorrectJetpack = (session) => {
    if (jetpack) {
      if (!session?.jetpackId?.includes(jetpack)) {
        return false;
      }
    }

    return true;
  };

  const hwRevisions = [
    ...new Set(
      rides.sessions
        .filter(sessionHasCorrectJetpack)
        .map(
          (session) => session?.hardwareRevision?.match(hwRevisionRegex)?.[0]
        )
        .filter((hw) => hw)
    ),
  ];

  return (
    <FormControl>
      <InputLabel shrink id="select-hw-revision-placeholder-label-label">
        HW
      </InputLabel>
      <Select
        labelId="select-hw-revision-placeholder-label-label"
        id="select-hw-revision-placeholder-label"
        value={hwRevision}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        displayEmpty
        className={classes.selectEmpty}
      >
        <MenuItem value="">All</MenuItem>
        {hwRevisions.map((hw) => (
          <MenuItem key={hw} value={hw}>
            {hw}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default HwRevisionDropdown;
