import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import Settings from "@material-ui/icons/Settings";

function DownloadJson({ data, title, icon }) {
  const handleClick = async () => {
    const tab = window.open("about:blank", "_blank");
    tab.document.write(
      `<html><pre>${JSON.stringify(data, null, 2)}</pre></html>`
    );
    tab.document.close();
  };

  return (
    <Tooltip title={title}>
      <IconButton
        color="primary"
        disabled={Boolean(!data)}
        onClick={handleClick}
      >
        {icon ? icon : <Settings />}
      </IconButton>
    </Tooltip>
  );
}

export default DownloadJson;
