import React from 'react';
import Error from '../../components/Error';

function MissingGps({ children }) {
  return (
    <>
      { children }
      <Error message="GPS data missing" />
    </>
  );
}

export default MissingGps;
