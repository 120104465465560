import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

function SearchField({ value, onChange, helperText, endAdornment }) {
  return (
    <TextField
      style={{ width: '100%', marginBottom: 10 }}
      variant="outlined"
      helperText={helperText}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      InputProps={{
        endAdornment,
      }}
    />
  );
}

SearchField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.string
};

SearchField.defaultProps = {
  value: '',
  onChange: (value) => value,
  helperText: '',
};

export default SearchField;
