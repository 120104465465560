import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { Drawer, SwipeableDrawer, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import qs from "query-string";
import SessionContainer from "./SessionContainer";
import Menu from "./Menu";

import "../assets/styles/batteries.css";
import styles from "./styles/appStyles";

const useStyles = makeStyles(styles);

function App({ drawerOpen, setDrawerOpen }) {
  const classes = useStyles();
  const location = useLocation();

  const { query, email, uid, mac, fromDate, toDate } = qs.parse(
    location.search
  );

  useEffect(() => {
    if (setDrawerOpen) {
      setDrawerOpen(false);
    }
  }, [setDrawerOpen]);

  return (
    <>
      <Hidden mdUp implementation="css">
        <SwipeableDrawer
          variant="temporary"
          open={drawerOpen}
          anchor="left"
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          onOpen={(e) => {
            if (
              e?.type === "keydown" &&
              (e?.key === "Tab" || e?.key === "Shift")
            ) {
              return;
            }

            setDrawerOpen(true);
          }}
          onClose={(e) => {
            if (
              e?.type === "keydown" &&
              (e?.key === "Tab" || e?.key === "Shift")
            ) {
              return;
            }

            setDrawerOpen(false);
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.drawerHeader} />
          <Menu />
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open
          anchor="left"
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
        >
          <div className={classes.drawerHeader} />
          <Menu
            query={query}
            email={email}
            uid={uid}
            mac={mac}
            fromDate={fromDate}
            toDate={toDate}
          />
        </Drawer>
      </Hidden>

      <Routes>
        <Route
          path="/sessions/:sessionId"
          element={
            <main className={clsx(classes.content, classes.contentShift)}>
              <SessionContainer />
            </main>
          }
        />
      </Routes>
    </>
  );
}

export default App;
