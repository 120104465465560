import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import Map from './Map';
import Options from './Options';
import SettingsDialog from './SettingsDialog';
import styles from '../../../styles/contentStyles';
import { useLocalStorage } from '../../../../hooks';

const useStyles = makeStyles(styles);

function MapContainer(props) {
  const {
    uniqueId,
    index,
    onClick,
  } = props;

  const initialMapSettings = {
    includeInvalidCoords: false,
    dmsFilter: true,
    onlyGoodCoords: true,
    distanceThreshold: 20,
    hdopThreshold: 5,
    speedThreshold: 60,
  };
  const [mapSettings, setMapSettings] = useLocalStorage('mapSettings', initialMapSettings);

  const [settingsOpen, setSettingsOpen] = useState(false);

  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} className={classes.mapContainer}>
        <Map
          session={uniqueId}
          index={index}
          includeInvalidCoords={mapSettings.includeInvalidCoords}
          dmsFilter={mapSettings.dmsFilter}
          onlyGoodCoords={mapSettings.onlyGoodCoords}
          distanceThreshold={mapSettings.distanceThreshold}
          hdopThreshold={mapSettings.hdopThreshold}
          speedThreshold={mapSettings.speedThreshold}
          onSettingsToggle={(e) => setSettingsOpen(!settingsOpen)}
          onClick={(entry) => {
            if(onClick) {
              onClick(entry);
            }
          }}
        />
      </Grid>
      <SettingsDialog open={settingsOpen} onClose={() => setSettingsOpen(!settingsOpen)}>
        <Options
          includeInvalidCoords={mapSettings.includeInvalidCoords}
          onIncludeInvalidCoordsChange={(val) => setMapSettings({ ...mapSettings, includeInvalidCoords: val })}
          dmsFilter={mapSettings.dmsFilter}
          onDmsFilterChange={(val) => setMapSettings({ ...mapSettings, dmsFilter: val })}
          onlyGoodCoords={mapSettings.onlyGoodCoords}
          onOnlyGoodCoordsChange={(val) => setMapSettings({ ...mapSettings, onlyGoodCoords: val })}
          distanceThreshold={mapSettings.distanceThreshold}
          onDistanceThresholdChange={(val) => setMapSettings({ ...mapSettings, distanceThreshold: val })}
          hdopThreshold={mapSettings.hdopThreshold}
          onHDOPThresholdChange={(val) => setMapSettings({ ...mapSettings, hdopThreshold: val })}
          speedThreshold={mapSettings.speedThreshold}
          onSpeedThresholdChange={(val) => setMapSettings({ ...mapSettings, speedThreshold: val })}
        />
      </SettingsDialog>
    </>
  );
}

export default MapContainer;