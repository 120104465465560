import React from 'react';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import GpsFilterSliders from './GpsFilterSliders';

function Options(props) {
  const {
    includeInvalidCoords,
    onIncludeInvalidCoordsChange,
    dmsFilter,
    onDmsFilterChange,
    onlyGoodCoords,
    onOnlyGoodCoordsChange,
    distanceThreshold,
    onDistanceThresholdChange,
    hdopThreshold,
    onHDOPThresholdChange,
    speedThreshold,
    onSpeedThresholdChange,
  } = props;

  return (
    <FormControl component="fieldset">
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeInvalidCoords}
              onChange={(e) => onIncludeInvalidCoordsChange(e.target.checked)}
              name="filterIncludeInvalidCoords"
              color="primary"
            />
          }
          label="Include invalid coords"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={dmsFilter}
              onChange={(e) => onDmsFilterChange(e.target.checked)}
              name="filterDMS"
              color="primary"
            />
          }
          label="Exclude coords where DMS is off"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={onlyGoodCoords}
              onChange={(e) => onOnlyGoodCoordsChange(e.target.checked)}
              name="filterOnlyGoodCoords"
              color="primary"
            />
          }
          label="Use only 'reasonable' coords"
        />
      </FormGroup>
      <GpsFilterSliders
        disabled={!onlyGoodCoords}
        distance={distanceThreshold}
        onDistanceThresholdChange={onDistanceThresholdChange}
        hdop={hdopThreshold}
        onHDOPThresholdChange={onHDOPThresholdChange}
        speed={speedThreshold}
        onSpeedThresholdChange={onSpeedThresholdChange}
      />
    </FormControl>
  );
}

export default Options;