import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  ReferenceArea,
  ReferenceLine,
} from "recharts";
import useZoom from "../../hooks/useZoom";
import CustomTooltip from "../../components/Chart/CustomTooltip";
import Card from "../../components/Card";

const useStyles = makeStyles({
  chart: {
    fontFamily: "Roboto",
    userSelect: "none",
    overflow: "visible",
    marginTop: "1%",
    "& .recharts-wrapper": {
      margin: "auto",
    },
    "& .recharts-default-tooltip": {
      textAlign: "center",
      "& p": {
        fontWeight: 700,
        textDecoration: "underline",
      },
    },
  },
});

function VoltageLineChart({
  XDomain,
  setXDomain,
  entries,
  currentIndex,
  setCurrentIndex,
}) {
  const classes = useStyles();

  const {
    zoomStart,
    tempZoomEnd,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
  } = useZoom(setXDomain);
  const handleClick = (e) => {
    if (!zoomStart && !tempZoomEnd) {
      if (e && e.activeLabel) {
        const index = entries.findIndex(
          (item) => new Date(item.timestamp).valueOf() === e?.activeLabel
        );

        setCurrentIndex(index);
      }
    }
  };

  if (!entries) {
    return null;
  }

  const voltages = [];
  const cells = entries
    .map((entry) => {
      let result = {
        timestamp: new Date(entry.timestamp).valueOf(),
        delta: Math.round((entry.energy?.cellVoltageDelta ?? 0) * 100) / 100,
        balancing: entry.states?.balancing ?? false,
        charging: entry.states?.charging ?? false,
      };

      if ((entry.energy?.cellVoltages ?? []).length === 0) {
        return null;
      }

      for (const index in entry.energy.cellVoltages) {
        voltages.push(entry.energy.cellVoltages[index]);
        result[`Row ${parseInt(index, 10) + 1}`] =
          entry.energy.cellVoltages[index];
      }

      return result;
    })
    .filter((e) => e !== null);

  const YDomain = [Math.min(2.5, ...voltages), Math.max(4.5, ...voltages)];

  const { balancingIntervals, chargingIntervals } = getIntervals(entries);
  const currentTimestamp = new Date(entries[currentIndex]?.timestamp).valueOf();

  const keys = [
    ...new Set(
      ...cells.map((cell) =>
        Object.keys(cell)
          .filter(
            (key) =>
              !["timestamp", "delta", "balancing", "charging"].includes(key)
          )
          .flat()
      )
    ),
  ];

  return (
    <Card title="Cell voltages">
      <ResponsiveContainer className={classes.chart} width="100%" height={200}>
        <LineChart
          data={cells}
          syncId="session"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onClick={handleClick}
        >
          {keys.map((key) => {
            return (
              <Line
                key={key}
                dataKey={key}
                yAxisId="left"
                name={`Row ${key}`}
                dot={false}
                stroke="#0eb6bd"
                connectNulls={true}
              />
            );
          })}
          {chargingIntervals.map((interval) => {
            return (
              <ReferenceArea
                key={interval.startTimestamp}
                x1={interval.startTimestamp}
                x2={interval.endTimestamp}
                yAxisId="left"
                fill="cyan"
                strokeOpacity={0.0}
                fillOpacity={0.1}
              />
            );
          })}
          {balancingIntervals.map((interval) => {
            return (
              <ReferenceArea
                key={interval.startTimestamp}
                x1={interval.startTimestamp}
                x2={interval.endTimestamp}
                yAxisId="left"
                stroke="red"
                fill="red"
                strokeOpacity={0.0}
                fillOpacity={0.2}
              />
            );
          })}
          {zoomStart && tempZoomEnd && (
            <ReferenceArea
              strokeOpacity={0}
              fillOpacity={0.1}
              x1={zoomStart}
              x2={tempZoomEnd}
              yAxisId="left"
            />
          )}
          <Tooltip
            content={
              <CustomTooltip
                unit="V"
                labelFormatter={(label) => new Date(label).toLocaleString()}
                extraDataKeys={["delta", "balancing", "charging"]}
              />
            }
          />
          <ReferenceLine x={currentTimestamp} yAxisId="left" stroke="white" />
          <XAxis
            type="number"
            domain={XDomain}
            dataKey="timestamp"
            textAnchor="end"
            allowDataOverflow={true}
            tick={true}
            stroke="#FFFFFF"
            tickFormatter={(ts) => {
              return new Date(ts).toLocaleTimeString();
            }}
          />
          <YAxis
            yAxisId="left"
            stroke="#FFFFFF"
            type="number"
            domain={YDomain}
            unit="V"
            tick
            allowDecimals
            allowDataOverflow
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
}

function getIntervals(entries) {
  const stopTime = new Date(entries.at(-1)).valueOf();
  const balancingIntervals = [];
  const chargingIntervals = [];

  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    const previousEntry = entries[i - 1];

    if (entry.states?.balancing && !previousEntry?.states?.balancing) {
      balancingIntervals.push({
        startTimestamp: new Date(entry.timestamp).valueOf(),
      });
    }

    if (!entry.states?.balancing && previousEntry?.states?.balancing) {
      balancingIntervals[balancingIntervals.length - 1].endTimestamp = new Date(
        entry.timestamp
      ).valueOf();
    }

    if (entry.states?.charging && !previousEntry?.states?.charging) {
      chargingIntervals.push({
        startTimestamp: new Date(entry.timestamp).valueOf(),
      });
    }

    if (!entry.states?.charging && previousEntry?.states?.charging) {
      chargingIntervals[chargingIntervals.length - 1].endTimestamp = new Date(
        entry.timestamp
      ).valueOf();
    }
  }

  for (const interval of balancingIntervals) {
    if (!interval.endTimestamp) {
      interval.endTimestamp = stopTime;
    }
  }

  for (const interval of chargingIntervals) {
    if (!interval.endTimestamp) {
      interval.endTimestamp = stopTime;
    }
  }

  return { balancingIntervals, chargingIntervals };
}

export default VoltageLineChart;
