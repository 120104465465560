import { useState } from 'react';

function useZoom(setZoom) {
  const [ zoomStart, setZoomStart ] = useState(null);
  const [ tempZoomEnd, setTempZoomEnd ] = useState(null);

  const handleMouseDown = (e) => {
    if (e && e.activeLabel) {
      setTempZoomEnd(null);
      setZoomStart(e.activeLabel);
    }
  };

  const handleMouseUp = (e) => {
    if (e && e.activeLabel) {
      if (zoomStart && tempZoomEnd) {
        setTempZoomEnd(null);
        setZoom([
          Math.min(zoomStart, e.activeLabel),
          Math.max(zoomStart, e.activeLabel)
        ]);
      }

      setZoomStart(null);
    }
  };

  const handleMouseMove = (e) => {
    if (e && e.activeLabel && zoomStart) {
      setTempZoomEnd(e.activeLabel);
    }
  }

  return {
    zoomStart,
    tempZoomEnd,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
  };
}

export default useZoom;

