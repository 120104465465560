import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { v4 as uuid } from 'uuid';
import { decamelize } from '../../../../utils';

function formatValue(key, value) {
  switch (key) {
    case 'dutyCycle': {
      return Math.round(value * 1000) / 1000;
    }

    default: {
      return value;
    }
  }
}

function McConf({ data, loading, children, title="Motor Controller", size = 6 }) {
  if (loading) {
    return (
      <Card>
        <CardHeader title={`${title} Configuration`} />
        <CardContent>
          {
            Array(size).fill(0).map(() => (
              <Skeleton key={uuid()} style={{ height: 37, marginBottom: 5 }} />
            ))
          }
        </CardContent>
      </Card>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Card>
      <CardHeader title={`${title} Configuration`} />
      <CardContent>
        { children }
        {
          data.length > 0
            ? (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Parameter</TableCell>
                      <TableCell>Min value</TableCell>
                      <TableCell>Max value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      Object
                        .entries((data[data.length - 1]).data)
                        .sort((a, b) => a[0].localeCompare(b[0]))
                        .map(([key, value]) => (
                          <TableRow key={`${key}_${value.timestamp}`}>
                            <TableCell>{decamelize(key)}</TableCell>
                            <TableCell>{formatValue(key, value.min)}</TableCell>
                            <TableCell>{formatValue(key, value.max)}</TableCell>
                          </TableRow>
                        ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            )
            : (
              <Typography variant="h6">No {title.toLowerCase()} config found</Typography>
            )
        }
      </CardContent>
    </Card>
  );
}

export default McConf;
