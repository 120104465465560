import React from 'react';

import Waves from '@material-ui/icons/Waves'

const routes = [
  { path: "/", exact: true },
  { text: "Rides", path: "/rides", icon: <Waves /> },
  { text: "Batteries", path: "/batteries" },
  { text: "Sessions", path: "/sessions" },
];

export default routes;
