import React, { useState } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import Graph from './Graph';
import { decamelize } from '../../../../../utils';

function EventGraphsContainer({
  sessionId,
  event,
  graphs,
  startTime,
  endTime,
  index,
  setIndex,
  timestamps,
}) {
  const [items, setItems] = useState(graphs);

  if (!graphs) {
    return null;
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container style={{ overflowY: 'scroll', width: '100%' }}>
        <Droppable droppableId="droppableGraph" style={{ width: '100%' }}>
          {
            (provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ width: '100%' }}
              >
                { items.map((graph, graphIndex) => (
                  <Grid item xs={12} key={`${event}_${graph.id}`}>
                    <Draggable
                      draggableId={graph.id}
                      index={graphIndex}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          {
                            items.length > 1
                              ? (
                                <Card style={{ margin: 10 }}>
                                  <CardHeader
                                    action={
                                      <IconButton { ...provided.dragHandleProps }>
                                        <DragHandleIcon />
                                      </IconButton>
                                    }
                                  title={<Typography variant="subtitle1">{decamelize(graph.id)}</Typography>}
                                    style={{ paddingTop: 5, paddingBottom: 5 }}
                                  />
                                  <CardContent style={{ paddingTop: 5, paddingBottom: 5 }}>
                                    <Graph
                                      sessionId={sessionId}
                                      eventId={event}
                                      graphId={graph.id}
                                      type={graph.type}
                                      data={graph.data}
                                      startTime={startTime}
                                      endTime={endTime}
                                      index={index}
                                      setIndex={(i) => setIndex(i)}
                                      draggableIndex={graphIndex}
                                      timestamps={timestamps}
                                    />
                                  </CardContent>
                                </Card>
                              )
                              : (
                                <Graph
                                  sessionId={sessionId}
                                  eventId={event}
                                  graphId={graph.id}
                                  type={graph.type}
                                  data={graph.data}
                                  startTime={startTime}
                                  endTime={endTime}
                                  index={index}
                                  setIndex={(i) => setIndex(i)}
                                  draggableIndex={graphIndex}
                                  timestamps={timestamps}
                                />
                              )
                          }
                        </div>
                      )
                    }
                    </Draggable>
                  </Grid>
                )) }
                {provided.placeholder}
              </div>
            )
          }
        </Droppable>
      </Grid>
    </DragDropContext>
  );
}

export default EventGraphsContainer;
