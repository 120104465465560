function styles(theme) {
  return {
    [theme.breakpoints.up('md')]: {
      mapContainer: {
        height: '30vh',
      },
      container: {
        height: '100%',
      },
      graphsContainer: {
        marginTop: 4,
        height: 'calc(70vh - 64px - 24px)',
      }
    }
  };
}

export default styles;
