import React from "react";
import {
  ConnStateAlias,
  ELogClass,
  EscAlias,
  GpsAlias,
  HcAlias,
  JpAlias,
  VbmsAlias,
} from "@radinnab/core-types";
import { ChartProps } from "../types";
import LineChart from "../GenericLineChart";
import { Grid } from "@material-ui/core";

type Props = {
  XDomain: ChartProps["XDomain"];
  startTimestamp: ChartProps["startTimestamp"];
  highlightedIndex: ChartProps["highlightedIndex"];
  setHighlightedIndex: ChartProps["setHighlightedIndex"];
} & {
  [key in ELogClass]?: ChartProps["entries"];
};

type Graph = {
  title: string;
  unit: string;
  yDomain?: ChartProps["YDomain"];
  yAxisType?: ChartProps["YAxisType"];
  logClass: ELogClass;
  attributes: {
    key: VbmsAlias | EscAlias | HcAlias | ConnStateAlias | JpAlias | GpsAlias;
    label: string;
    formatter?: (value: unknown) => string | number;
  }[];
};

const graphs: Graph[] = [
  {
    title: "Bus bar temperatures",
    unit: "°C",
    logClass: ELogClass.Vbms,
    attributes: [
      {
        key: VbmsAlias.Busbar0Temperature,
        label: "Bus bar 1",
      },
      {
        key: VbmsAlias.Busbar1Temperature,
        label: "Bus bar 2",
      },
    ],
  },
  {
    title: "VBMS Mosfet temperatures",
    unit: "°C",
    logClass: ELogClass.Vbms,
    attributes: [
      {
        key: VbmsAlias.Mosfet0Temperature,
        label: "Mosfet 1",
      },
      {
        key: VbmsAlias.Mosfet1Temperature,
        label: "Mosfet 2",
      },
    ],
  },
  {
    title: "Cell temperatures",
    unit: "°C",
    logClass: ELogClass.Vbms,
    attributes: [
      {
        key: VbmsAlias.TemperatureCell0,
        label: "Cell 1",
      },
      {
        key: VbmsAlias.TemperatureCell1,
        label: "Cell 2",
      },
      {
        key: VbmsAlias.TemperatureCell2,
        label: "Cell 3",
      },
    ],
  },
  {
    title: "Balance circuit temperature",
    unit: "°C",
    logClass: ELogClass.Vbms,
    attributes: [
      {
        key: VbmsAlias.BalanceTemperature,
        label: "Balance circuit",
      },
    ],
  },
  {
    title: "State of charge",
    unit: "%",
    yDomain: [0, 100],
    logClass: ELogClass.Vbms,
    attributes: [
      {
        key: VbmsAlias.StateOfCharge,
        label: "State of charge",
      },
    ],
  },
  {
    title: "Total voltage",
    unit: "V",
    logClass: ELogClass.Vbms,
    attributes: [
      {
        key: VbmsAlias.TotalVoltage,
        label: "Total voltage",
      },
    ],
  },
  {
    title: "Battery plus voltage",
    unit: "V",
    logClass: ELogClass.Vbms,
    attributes: [
      {
        key: VbmsAlias.BatteryPlusVoltage,
        label: "Battery plus voltage",
      },
    ],
  },
  {
    title: "Fuse voltage",
    unit: "V",
    logClass: ELogClass.Vbms,
    attributes: [
      {
        key: VbmsAlias.FuseVoltage,
        label: "Fuse voltage",
      },
    ],
  },
  {
    title: "Cell voltages",
    unit: "V",
    logClass: ELogClass.Vbms,
    attributes: [
      {
        key: VbmsAlias.CellVoltage0,
        label: "Cell 1",
      },
      {
        key: VbmsAlias.CellVoltage1,
        label: "Cell 2",
      },
      {
        key: VbmsAlias.CellVoltage2,
        label: "Cell 3",
      },
      {
        key: VbmsAlias.CellVoltage3,
        label: "Cell 4",
      },
      {
        key: VbmsAlias.CellVoltage4,
        label: "Cell 5",
      },
      {
        key: VbmsAlias.CellVoltage5,
        label: "Cell 6",
      },
      {
        key: VbmsAlias.CellVoltage6,
        label: "Cell 7",
      },
      {
        key: VbmsAlias.CellVoltage7,
        label: "Cell 8",
      },
      {
        key: VbmsAlias.CellVoltage8,
        label: "Cell 9",
      },
      {
        key: VbmsAlias.CellVoltage9,
        label: "Cell 10",
      },
      {
        key: VbmsAlias.CellVoltage10,
        label: "Cell 11",
      },
      {
        key: VbmsAlias.CellVoltage11,
        label: "Cell 12",
      },
      {
        key: VbmsAlias.CellVoltage12,
        label: "Cell 13",
      },
      {
        key: VbmsAlias.CellVoltage13,
        label: "Cell 14",
      },
    ],
  },
  {
    title: "Input current",
    unit: "A",
    logClass: ELogClass.Vbms,
    attributes: [
      {
        key: VbmsAlias.InputCurrent,
        label: "Input current",
      },
      {
        key: VbmsAlias.InputCurrentLtc,
        label: "Input current LTC",
      },
    ],
  },
  {
    title: "Peak current",
    unit: "A",
    logClass: ELogClass.Vbms,
    attributes: [
      {
        key: VbmsAlias.PeakCurrent,
        label: "Peak current",
      },
      {
        key: VbmsAlias.PeakCurrentLtc,
        label: "Peak current LTC",
      },
    ],
  },
  {
    title: "ESC PCB temperature",
    unit: "°C",
    logClass: ELogClass.Esc,
    attributes: [
      {
        key: EscAlias.PcbTemperature,
        label: "PCB",
      },
    ],
  },
  {
    title: "ESC mosfet temperatures",
    unit: "°C",
    logClass: ELogClass.Esc,
    attributes: [
      {
        key: EscAlias.TemperatureMosfet1,
        label: "Mosfet 1",
      },
      {
        key: EscAlias.TemperatureMosfet2,
        label: "Mosfet 2",
      },
      {
        key: EscAlias.TemperatureMosfet3,
        label: "Mosfet 3",
      },
    ],
  },
  {
    title: "ESC Humidity",
    unit: "%",
    logClass: ELogClass.Esc,
    attributes: [
      {
        key: EscAlias.PcbHumidity,
        label: "PCB",
      },
    ],
  },
  {
    title: "ESC Current",
    unit: "A",
    logClass: ELogClass.Esc,
    attributes: [
      {
        key: EscAlias.InputCurrent,
        label: "Input current",
      },
      {
        key: EscAlias.MotorCurrent,
        label: "Motor current",
      },
    ],
  },
  {
    title: "ERPM",
    unit: "",
    logClass: ELogClass.Esc,
    attributes: [
      {
        key: EscAlias.Erpm,
        label: "ERPM",
      },
    ],
  },
  {
    title: "Input voltage",
    unit: "V",
    logClass: ELogClass.Esc,
    attributes: [
      {
        key: EscAlias.InputVoltage,
        label: "Input voltage",
      },
    ],
  },
  {
    title: "HC RSSI",
    unit: "",
    logClass: ELogClass.ConnState,
    attributes: [
      {
        key: ConnStateAlias.Rssi,
        label: "RSSI",
      },
    ],
  },
  {
    title: "DMS",
    unit: "",
    yDomain: ["Off", "On"],
    yAxisType: "category",
    logClass: ELogClass.JpSensors,
    attributes: [
      {
        key: JpAlias.Dms,
        label: "DMS",
        formatter: (value: unknown) => (Boolean(value) ? "On" : "Off"),
      },
    ],
  },
  {
    title: "JP Humidity",
    unit: "%",
    logClass: ELogClass.JpSensors,
    attributes: [
      {
        key: JpAlias.Humidity,
        label: "Humidity",
      },
    ],
  },
  {
    title: "JP Temperature",
    unit: "°C",
    logClass: ELogClass.JpSensors,
    attributes: [
      {
        key: JpAlias.Humidity,
        label: "Temperature",
      },
    ],
  },
  {
    title: "HC throttle",
    unit: "",
    logClass: ELogClass.Hc,
    attributes: [
      {
        key: HcAlias.Throttle,
        label: "Throttle",
      },
    ],
  },
  {
    title: "Speed",
    unit: "km/h",
    logClass: ELogClass.Gps,
    attributes: [
      {
        key: GpsAlias.Speed,
        label: "Speed",
        formatter: (value: unknown) => Number(value).toFixed(1),
      },
    ],
  },
  {
    title: "HDOP",
    unit: "",
    logClass: ELogClass.Gps,
    attributes: [
      {
        key: GpsAlias.Hdop,
        label: "HDOP",
      },
    ],
  },
];

function ChartsContainer(props: Props) {
  const filteredGraphs = graphs.filter((graph) => {
    return Boolean(
      props[graph.logClass]?.find((entry) => {
        return graph.attributes.some(
          (attribute) =>
            entry[attribute.key] !== undefined && entry[attribute.key] !== null
        );
      })
    );
  });

  return (
    <Grid container spacing={1}>
      {filteredGraphs.map((graph) => (
        <Grid
          key={`${graph.logClass}_${graph.title}`}
          item
          xs={12}
          md={6}
          xl={4}
        >
          <LineChart
            title={graph.title}
            unit={graph.unit}
            XDomain={props.XDomain}
            YAxisType={graph.yAxisType ?? "number"}
            YDomain={graph.yDomain}
            startTimestamp={props.startTimestamp}
            entries={props[graph.logClass] ?? []}
            attributes={graph.attributes}
            highlightedIndex={props.highlightedIndex ?? 0}
            setHighlightedIndex={props.setHighlightedIndex}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default ChartsContainer;
