import React from 'react';
import binarySearch from 'binarysearch';
import gpsDistance from 'gps-distance';
import { IconButton, Tooltip } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import GoogleMap from '../../../../components/GoogleMap';
import LoadingGps from '../../LoadingGps';
import MissingGps from '../../MissingGps';
import { useSessionData } from '../../../../hooks';

function getClosestTimestampIndex(timestamps, ts) {
  const res = timestamps.findIndex((item, index) => (
    ts >= item &&
    ts <= timestamps[index + 1]
  ));

  return res;
}

function getCurrentPosition(coords, timestamps, index) {
  const closestTimestampIndex = getClosestTimestampIndex(timestamps, index);
  const currentPos = coords[closestTimestampIndex];

  return currentPos || coords[0];
}

function isGpsPosValid(lat, long) {
  return (
    long < 9999 &&
    lat < 100 &&
    !(Math.abs(Math.round(long)) === 0 && Math.abs(Math.round(lat)) === 0)
  );
}

function hasDMSEnabled(dmsIntervals, timestamp) {
  const index = binarySearch(dmsIntervals, timestamp, (interval, ts) => {
    if (ts < interval.start) {
      return 1;
    } else if (ts > interval.end) {
      return -1;
    }

    return 0;
  });

  return Boolean(Math.max(0, index + 1));
}

function isGoodCoord(coord, prevCoord, thresholds) {
  if (!prevCoord) {
    return isGpsPosValid(coord?.latitude, coord?.longitude);
  }

  if (!isGpsPosValid(coord.latitude, coord.longitude)) {
    return false;
  }

  const distance = gpsDistance(
    prevCoord?.latitude || coord?.latitude,
    prevCoord?.longitude || coord?.longitude,
    coord?.latitude,
    coord?.longitude,
  ) * 1000;

  return Boolean(
    distance <= thresholds.distance
    && coord.hdop <= thresholds.hdop
    && coord.speed <= thresholds.speed
  );
}

function getReasonableCoords(coords, thresholds) {
  return coords.filter((coord, index) => isGoodCoord(coord, coords[index - 1], thresholds));
}

function getDMSIntervals(dms, startTime, endTime) {
  const toggles = dms.map(toggle => ({
    type: (toggle?.data ?? toggle?.dms) === true ? 'start' : 'end',
    timestamp: toggle.timestamp,
  }));

  if (!toggles || toggles.length === 0) {
    toggles.push({
      type: 'start',
      timestamp: startTime,
    });
  }

  if (toggles[toggles.length - 1].type === 'start') {
    toggles.push({
      type: 'end',
      timestamp: endTime,
    });
  }

  const intervals = [];

  for (let i = 0; i < toggles.length; ++i) {
    if (toggles[i].type === 'start') {
      intervals.push({ start: toggles[i].timestamp });
    } else if (intervals[intervals.length - 1] && toggles[i].type === 'end') {
      intervals[intervals.length - 1] = { ...intervals[intervals.length - 1], end: toggles[i].timestamp };
    }
  }

  return intervals;
}

function Map(props) {
  const {
    session,
    index,
    includeInvalidCoords,
    dmsFilter,
    onlyGoodCoords,
    distanceThreshold,
    hdopThreshold,
    speedThreshold,
    onSettingsToggle,
    onClick,
  } = props;

  const [gps, gpsLoading] = useSessionData(session, 'gps');
  const [dms, dmsLoading] = useSessionData(session, 'dmsState');

  if (gpsLoading || dmsLoading) {
    return <LoadingGps />;
  }

  const startTime = gps?.find(coord => coord.timestamp)?.timestamp ?? 0;
  const endTime = gps?.[gps.length - 1]?.timestamp ?? 0;

  let coords = gps.map(entry => {
    if (entry.lat && entry.long) {
      return {
        ...entry,
        latitude: entry.lat,
        longitude: entry.long,
      }
    }

    return entry;
  });

  if (!includeInvalidCoords) {
    coords = coords?.filter((coord) => isGpsPosValid(coord?.latitude, coord?.longitude)) ?? [];
  }

  if (dmsFilter && dms.length > 0) {
    const dmsIntervals = getDMSIntervals(dms, startTime, endTime);

    coords = coords?.filter(coord => {
      return coord.latitude
        && coord.longitude
        && hasDMSEnabled(dmsIntervals, coord.timestamp)
    }) ?? [];
  }

  if (onlyGoodCoords) {
    coords = getReasonableCoords(
      coords,
      {
        distance: distanceThreshold,
        hdop: hdopThreshold,
        speed: speedThreshold,
      },
    );
  }

  const currentPos = getCurrentPosition(
    coords,
    coords.map(c => c.timestamp),
    index,
  );

  if (!gps || gps.length === 0 || coords.length === 0) {
    return (
      <MissingGps>
        <SettingsButton onClick={onSettingsToggle} />
      </MissingGps>
    );
  }

  const filteredCoords = coords
    .filter(coord => coord.latitude && coord.longitude);

  return (
    <GoogleMap
      apiKey='AIzaSyANtnIImHnVcWbMjJ0bWMOKa_e0F6Ugd6U'
      data={
        filteredCoords
          .map(coord => ({ lat: coord.latitude, lng: coord.longitude }))
      }
      centerLat={coords[0].latitude}
      centerLng={coords[0].longitude}
      currentPos={{
        lat: currentPos.latitude,
        lng: currentPos.longitude,
      }}
      onClick={(index) => {
        if (onClick) {
          onClick(filteredCoords[index]);
        }
      }}
    >
      <SettingsButton onClick={onSettingsToggle} />
    </GoogleMap>
  );
}

function SettingsButton({ onClick }) {
  return (
    <Tooltip title="Settings">
      <IconButton
        onClick={onClick}
      >
        <SettingsIcon />
      </IconButton>
    </Tooltip>
  );
}

export default Map;
