function transformData(event, data) {
  const blacklist = [
    'id',
    'timestamp',
    'latitude',
    'longitude',
    'lat',
    'long',
    'fwVers',
    'walltime',
  ];

  if (!data) {
    return [];
  }

  const keys = [...new Set(
    data
      .map(point => {
        if (point.hasOwnProperty('data') && typeof point.data === 'object') {
          return Object.keys(point.data);
        }

        if (point.hasOwnProperty('data') && typeof point.data !== 'object') {
          return [event];
        }

        return Object.keys(point);
      })
      .flat()
  )].filter(key => !blacklist.includes(key)).sort((a, b) => a.localeCompare(b));

  return keys.map(key => {
    const firstPoint = data.find(point => typeof point?.[key] !== 'undefined');
    const firstPointHasDataKey = firstPoint?.hasOwnProperty('data') ?? false;
    const type = firstPointHasDataKey ? typeof firstPoint?.data : typeof firstPoint?.[key];

    const result = {
      id: key,
      type,
      data: data
        .map(point => {
          let value = point[key];
          if (point.hasOwnProperty('data')) {
            if (typeof point.data === 'object') {
              value = point.data[key];
            } else {
              key = event;
              value = typeof point.data === 'number' ? point.data : Number(point.data);
            }
          } else {
            if (typeof value === 'number') {
              value = point[key];
            } else {
              value = point[key] !== undefined ? Number(point[key]) : undefined;
            }
          }


          return ({ id: key, timestamp: point.timestamp, [key]: value ?? null })
        })
        .sort((a, b) => a.timestamp - b.timestamp)
    };

    return result;
  });
}

export default transformData;
