function styles(theme) {
  return {
    card: {
      maxHeight: '100%',
      overflowY: 'scroll',
    },
    container: {
      height: '100%',
    },
    list: {
      paddingBottom: 25,
    },
    listItem: {
      padding: 2,
    },
  };
}

export default styles;
