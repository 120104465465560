import React, { useState } from "react";
import { useParams } from "react-router";
import { Grid, LinearProgress } from "@material-ui/core";
import { useSession } from "../hooks/useSessions";
import Error from "../components/Error";
import { AxisDomain } from "recharts/types/util/types";
import { ELogClass, GpsEntry } from "@radinnab/core-types";
import ChartsContainer from "./content/ChartsContainer";
import MetadataCard from "./content/MetadataCard";
import SummaryCard from "./content/SummaryCard";
import Map from "./content/Map";

function SessionContainer() {
  const { sessionId } = useParams();
  const [data, loading, error] = useSession(sessionId);
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Error message={`Failed to fetch session data: ${error}`} />;
  }

  if (!data) {
    return <Error message={`No session data found.`} />;
  }

  const ticks = Object.values(data.data)
    .flat()
    .map((entry) => entry.timestamp)
    .sort((a, b) => a - b);

  const xDomain: AxisDomain = [
    data.metadata.timestamps.start ?? 0,
    data.metadata.timestamps.stop ?? 0,
  ];

  const vbms = interpolate(ticks, data.data?.[ELogClass.Vbms] ?? []);
  const esc = interpolate(ticks, data.data?.[ELogClass.Esc] ?? []);
  const hc = interpolate(ticks, data.data?.[ELogClass.Hc] ?? []);
  const connState = interpolate(ticks, data.data?.[ELogClass.ConnState] ?? []);
  const jpSensors = interpolate(ticks, data.data?.[ELogClass.JpSensors] ?? []);
  const gps = interpolate(
    ticks,
    data.data?.[ELogClass.Gps] ?? []
  ) as GpsEntry[];

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Map coords={gps} highlightedIndex={highlightedIndex} />
      </Grid>
      <Grid item xs={12} md={6}>
        <MetadataCard id={data.metadata.id} metadata={data.metadata} />
      </Grid>
      <Grid item xs={12} md={6}>
        <SummaryCard summary={data.metadata.summary} />
      </Grid>
      <Grid item xs={12}>
        <ChartsContainer
          XDomain={xDomain}
          startTimestamp={data.metadata.startDate?.valueOf() ?? 0}
          highlightedIndex={highlightedIndex}
          setHighlightedIndex={setHighlightedIndex}
          vbms={vbms}
          esc={esc}
          hc={hc}
          conn_state={connState}
          jp_sensors={jpSensors}
          gps={gps}
        />
      </Grid>
    </Grid>
  );
}

function interpolate(timestamps: number[], entries: Record<string, unknown>[]) {
  const result = [];
  const keys = new Set([
    ...entries
      .map((entry) => Object.keys(entry))
      .flat()
      .filter((key) => key !== "timestamp"),
  ]);

  for (const ts of timestamps) {
    const entry = entries.find((entry) => entry.timestamp === ts);
    const prevEntry = result.at(-1);

    let newEntry: Record<string, unknown> = {
      timestamp: ts,
    };

    for (const key of keys) {
      const value = (entry as any)?.[key] ?? (prevEntry as any)?.[key] ?? null;
      newEntry[key] = value;
    }

    result.push(newEntry);
  }

  return result;
}

export default SessionContainer;
