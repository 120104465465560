import React, { useState } from "react";
import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
} from "@material-ui/core";
import Card from "../../components/Card";
import { Session, GenericAlias, EscAlias } from "@radinnab/core-types";

type Props = {
  id: Session["metadata"]["id"];
  metadata: Session["metadata"];
};

function snakeToTitleCase(s: string): string {
  return s
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase()); // First char after each -/_
}

function formatKey(key: string): string {
  switch (key) {
    case EscAlias.Uuid: {
      return "UUID";
    }

    case "i2c_communication_ok": {
      return "I2C Communication OK";
    }

    case GenericAlias.RiderId: {
      return "Rider ID";
    }

    case GenericAlias.HardwareId: {
      return "Hardware ID";
    }

    default: {
      return snakeToTitleCase(key);
    }
  }
}

function MetadataCard({ id, metadata }: Props) {
  const [selectedTab, setSelectedTab] = useState(0);
  const entries = Object.entries(metadata.classes).sort(([a], [b]) =>
    a.localeCompare(b)
  );
  const keys = entries.map(([key]) => key);
  const [selectedLogClass, currentMetadata] = entries[selectedTab];

  return (
    <Card title={`Session ${id}`}>
      <Tabs
        value={selectedTab}
        onChange={(event, newTab) => setSelectedTab(newTab)}
        indicatorColor="primary"
        textColor="primary"
      >
        {keys.map((key) => (
          <Tab key={key} label={key} />
        ))}
      </Tabs>
      <Table size="small">
        <TableBody>
          {Object.entries(currentMetadata)
            .filter(([, value]) => value !== null && value !== undefined)
            .sort(([a], [b]) => a.localeCompare(b))
            .map(([key, value]) => (
              <TableRow key={`${selectedLogClass}_${key}`}>
                <TableCell>{formatKey(key)}</TableCell>
                <TableCell>{value.toString()}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Card>
  );
}

export default MetadataCard;
