import React from "react";
import {
  Grid,
} from "@material-ui/core";
import VoltageChart from "./VoltageChart";
import Card from "../../components/Card";
import TemperatureChart from "./TemperatureChart";

function SnapshotContainer({ data }) {
  const formattedTimestamp = new Date(data?.timestamp).toLocaleString('sv-SE');

  if (!data) return null;

  let { cells, ...rest } = data?.environment?.temperatures ?? {};

  if (!cells) {
    const { cellTemps, ...others } = data?.environment ?? {};
    cells = cellTemps;
    rest = others;
  }

  if (
    (data?.energy?.cellVoltages ?? []).length === 0 &&
    (cells ?? []).length === 0
  ) return null;

  return (
    <Card title={`Snapshot - ${formattedTimestamp}`}>
      <Grid container spacing={1}>
        <Grid item xs={12} xl={6}>
          <VoltageChart
            voltages={data?.energy?.cellVoltages ?? []}
            balancing={data?.energy?.balancing ?? []}
            delta={data?.energy?.cellVoltageDelta}
            title="Voltage"
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <TemperatureChart
            temps={{
              cells,
              ...rest
            }}
            title="Temperature"
          />
        </Grid>
      </Grid>
    </Card>
  );
}

export default SnapshotContainer;

