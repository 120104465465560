export default (theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(256,256,256,0.2)' : 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
  list: {
    width: 250, // drawer width
  },
  errorMsg: {
    width: '100%',
    display: 'block',
    textAlign: 'center',
    marginTop: '5vh',
  },
  link: {
    textAlign: 'center',
    color: 'black',
    textDecoration: 'none',
    marginRight: 15,
    padding: 5,
  },
  linearProgress: {
    width: '100%',
    display: 'block',
    textAlign: 'center',
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  logo: {
    display: 'block',
    minWidth: '10%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '30%',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '50%',
    },
  }
});
