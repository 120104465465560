import React, { useState } from 'react';
import { Grid, Tabs, Tab, Typography, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Routes, Route, Link, useLocation } from "react-router-dom";
import {
  Events,
  Metadata,
  Errors,
  McConf,
  TemporaryMode,
  Weather,
} from "./panels";
import MapContainer from "./map/MapContainer";
import styles from "../../styles/contentStyles";
import { useSessionData } from "../../../hooks/useRide";
import useQueryParams from "../../../hooks/useQueryParams";

const useStyles = makeStyles(styles);

function ContentContainer({ session }) {
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const location = useLocation();
  const query = useQueryParams();

  let [errors, errorsLoading] = useSessionData(session.id, "errCode");

  let [mcConf, mcConfLoading] = useSessionData(session.id, "vescMcconf");

  let timestamps = {};

  const [constants, constantsLoading] = useSessionData(session.id, "constants");
  const [generic, genericLoading] = useSessionData(session.id, "generic");

  const [tmpMode, tmpModeLoading] = useSessionData(session.id, "tmpMode");

  const firstValidTimestampEntry = constants.find((entry) => entry?.timestamps);
  const firstValidConstantsEntry = constants.find((entry) => entry?.esc?.conf);

  if (firstValidTimestampEntry) {
    timestamps = firstValidTimestampEntry.timestamps;
  }

  if (firstValidConstantsEntry) {
    mcConf = [
      {
        data: {
          ...firstValidConstantsEntry.esc.conf,
        },
      },
    ];

    errors = generic
      .filter((entry) => entry.errCode)
      .map((entry) => ({
        data: entry.errCode,
        timestamp: entry.timestamp,
      }));

    timestamps = firstValidConstantsEntry?.timestamps;
  }

  return (
    <div>
      <Grid
        item
        xs={12}
        style={{ height: "100%", marginBottom: 50, position: "relative" }}
      >
        <MapContainer
          uniqueId={session?.uniqueId}
          index={index}
          onClick={(e) => setIndex(e.timestamp)}
        />
        <Grid container spacing={1} className={classes.graphsContainer}>
          <Grid item xs={12}>
            <Tabs
              value={location.pathname}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label="Graphs"
                value={`/rides/${session.id}`}
                component={Link}
                to={`/rides/${session.id}${query}`}
              />
              <Tab
                label="Metadata"
                value={`/rides/${session.id}/metadata`}
                component={Link}
                to={`/rides/${session.id}/metadata${query}`}
              />
              <Tab
                label="Motor Controller"
                value={`/rides/${session.id}/mcconf`}
                component={Link}
                to={`/rides/${session.id}/mcconf${query}`}
              />
              <Tab
                label="Temporary Mode"
                value={`/rides/${session.id}/trialmode`}
                component={Link}
                to={`/rides/${session.id}/trialmode${query}`}
              />
              <Tab
                label={
                  <Badge
                    badgeContent={errors?.length ?? undefined}
                    color="error"
                    max={99}
                  >
                    Errors
                  </Badge>
                }
                value={`/rides/${session.id}/errors`}
                component={Link}
                to={`/rides/${session.id}/errors${query}`}
              />
              <Tab
                label="Weather"
                value={`/rides/${session.id}/weather`}
                component={Link}
                to={`/rides/${session.id}/weather${query}`}
              />
            </Tabs>
            <Routes>
              <Route
                path={`/metadata`}
                element={<Metadata session={session} />}
              />

              <Route
                path={`/mcconf`}
                element={
                  <McConf
                    data={mcConf}
                    loading={mcConfLoading || constantsLoading}
                  />
                }
              />

              <Route
                path={`/trialmode`}
                element={
                  <TemporaryMode data={tmpMode} loading={tmpModeLoading} />
                }
              />

              <Route
                path={`/errors`}
                element={
                  <Errors
                    errors={errors}
                    loading={errorsLoading || genericLoading}
                    startTime={session.startDateTime}
                  />
                }
              />

              <Route
                path={`/weather`}
                element={<Weather weather={session.weather} />}
              />

              <Route
                path={`/`}
                element={
                  <Events
                    session={session.id}
                    events={session.subCollections}
                    startTime={session.startDateTime}
                    endTime={session.endDateTime}
                    index={index}
                    setIndex={setIndex}
                    timestamps={timestamps}
                  />
                }
              />

              <Route
                element={<Typography variant="">Nothing here</Typography>}
              />
            </Routes>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ContentContainer;
