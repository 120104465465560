import React from "react";
import {
  Card as MaterialCard,
  CardHeader,
  CardContent,
  CardActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  card: {
    overflow: "visible",
  },
  cardContent: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  cardHeader: {
    paddingTop: 5,
    paddingBottom: 5,
  },
});

function Card({
  title,
  children,
  subheader,
  headerAction,
  actions,
}: CardProps) {
  const classes = useStyles();

  return (
    <MaterialCard className={classes.card}>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: "subtitle1" }}
        subheader={subheader}
        action={headerAction}
        className={classes.cardHeader}
      />
      <CardContent className={classes.cardContent}>{children}</CardContent>
      {actions && <CardActions>{actions}</CardActions>}
    </MaterialCard>
  );
}

type CardProps = {
  title: string;
  subheader?: string;
  headerAction?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
};

export default Card;
