import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router';
import moment from 'moment';
import qs from 'query-string';

import SearchPanel from './SearchPanel';
import Previews from './previews/Previews';

import { useRides } from '../../hooks';
import styles from '../styles/menuStyles';

const useStyles = makeStyles(styles);

function prettifyMac(mac) {
  if (mac) {
    return mac.trim().replace(/:\s*/g, '');
  }

  return mac;
}

function Menu({ session }) {
  const location = useLocation();
  const classes = useStyles();

  let {
    jetpack,
    rider,
    battery,
    fromDate,
    toDate,
  } = qs.parse(location.search);

  if (!jetpack && !rider && !battery && !fromDate && !toDate) {
    if (!fromDate) {
      fromDate = moment().add(-1, 'week').format('YYYY-MM-DD');
    }

    if (!toDate) {
      toDate = moment().add(1, 'day').format('YYYY-MM-DD');
    }
  }

  const [ runs, runsLoading ] = useRides({
    jetpackId: prettifyMac(jetpack),
    batteryId: prettifyMac(battery),
    riderId: rider,
    fromDate,
    toDate,
  });

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item xs={12} className={classes.searchForm}>
        <SearchPanel
          jetpack={jetpack}
          battery={battery}
          rider={rider}
          fromDate={fromDate}
          toDate={toDate}
          sessionId={session}
        />
      </Grid>
      <Grid item xs={12} className={classes.sessionsContainer}>
        <Previews
          loading={runsLoading}
          rides={runs}
          selected={session}
        />
      </Grid>
    </Grid>
  );
}

export default Menu;
