import { useFetch } from 'use-http';
import qs from 'query-string';
import constants from '../rides/constants';

const macRegex = new RegExp(/(0[xX])?(([\da-fA-F]:?){2}){6}/);

const { ENDPOINT_RUNS } = constants;

const options = {
  retries: 2,
  retryOn: ({ response }) => {
    return response && response.status >= 300
  },
  retryDelay: () => {
    return 1000;
  }
};

function useSessionHeader(sessionId) {
  const { loading, error, data = null } = useFetch(
    `${ENDPOINT_RUNS}/${sessionId}/header`,
    options,
    [sessionId],
  );

  if (error) {
    console.error(error);
  }

  return [data?.data ?? {}, loading, error];
}

function useSessionData(sessionId, subCollectionId) {
  const { loading, error, data = null } = useFetch(
    `${ENDPOINT_RUNS}/${sessionId}/${subCollectionId}`,
    options,
    [sessionId, subCollectionId],
  );

  if (error) {
    console.error(error);
  }

  return [data?.data ?? [], loading, error];
}

function useRide(sessionId)  {
  const { loading, error, data = null } = useFetch(
    `${ENDPOINT_RUNS}/${sessionId}`,
    options,
    [sessionId],
  );

  return [ data?.data, loading, error ];
}

function useRides(params) {
  const queryString = qs.stringify(
    Object.entries(params)
      .filter(([key, value]) => value)
      .map(([key, value]) => {
        if (macRegex.test(value)) {
          value = `0x${value
            .replace(/:/g, '')
            .replace('0x', '')
            .toUpperCase()}`;
        }

        return [key, value];
      })
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
  );

  const { loading, error, data = null } = useFetch(
    `${ENDPOINT_RUNS}/sessions${queryString && `?${queryString}`}`,
    { ...options, cachePolicy: 'no-cache', persist: false },
    [queryString],
  );

  return [ data?.data, loading, error ];
}

export {
  useSessionHeader,
  useSessionData,
  useRide,
  useRides,
};

